import SysModels from "../../models";
import fetcher from "../Fetcher";

class FullTemplateEntityService {
  get = async (id: string) => {
    const url = `/FullTemplateEntity/${id}`;
    return fetcher.get<SysModels.FullTemplateEntityOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.FullTemplateEntitySearchInputDto
  ) => {
    const url = `/FullTemplateEntity/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.FullTemplateEntityGridOutputDto>(url, model);
  };

  listParticipants = async (
    page: number,
    pageSize: number,
    model: SysModels.FulTemplateParticipantSearchDto
  ) => {
    const url = `/FullTemplateEntity/ListParticipants/${page}/${pageSize}`;
    return fetcher.post<SysModels.FullTemplateParticipantGridOutputDto>(
      url,
      model
    );
  };

  create = async (model: SysModels.FullTemplateEntityInsertDto) => {
    const url = `/FullTemplateEntity`;
    return fetcher.post<SysModels.FullTemplateEntityOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.FullTemplateEntityUpdateDto) => {
    const url = `/FullTemplateEntity/${id}`;
    return fetcher.put<SysModels.FullTemplateEntityOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/FullTemplateEntity/${id}`;
    return fetcher.delete<any>(url);
  };

  updateParticipants = async (
    id: string,
    model: SysModels.FullTemplateParticipantsUpdateDto
  ) => {
    const url = `/FullTemplateEntity/UpdateParticipants/${id}`;
    return fetcher.put<SysModels.FullTemplateEntityOutputDto>(url, model);
  };
}

const fullTemplateEntity = new FullTemplateEntityService();
export default fullTemplateEntity;
