import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityFileService {
  upload = async (data: FormData) => {
    const url = `/EntityFile/Upload`;
    return fetcher.postFormData<any>(url, data);
  };

  getMaxFileSize = async () => {
    const url = `/EntityFile/GetMaxFileSize`;
    return fetcher.get<number>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.EntityFileSearchInputDto
  ) => {
    const url = `/EntityFile/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.EntityFileGridOutputDto>(url, model);
  };

  update = async (id: string, data: FormData) => {
    const url = `/EntityFile/Update/${id}`;
    return fetcher.putFormData<any>(url, data);
  };

  delete = async (id: string) => {
    const url = `/EntityFile/${id}`;
    return fetcher.delete<any>(url);
  };
}

const entityFile = new EntityFileService();
export default entityFile;
