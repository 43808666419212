import React, { useEffect, useMemo, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import { Dropdown } from "react-bootstrap";
import {
  BOOLEAN_FILTERS,
  GetDisplayForBooleanFilter,
} from "../services/CommonService";
import {
  useCompanySettings,
  useLastPageFilters,
  useLoginStatus,
} from "../stores/SystemStore";
import SysModels from "../models";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import YesNoChip from "../components/YesNoChip";

function Users(props: any) {
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [role, setRole] = useState<number>();
  const [isAllowLogIn, setIsAllowLogIn] = useState<boolean | undefined>(
    undefined
  );
  const [isAllowReminders, setIsAllowReminders] = useState<boolean | undefined>(
    undefined
  );
  const [isArchived, setIsArchived] = useState<boolean | undefined>(false);
  const [filters, setFilters] = useState<
    SysModels.UserSearchInputDto & { userTypeName?: string }
  >({
    allowLogIn: undefined,
    allowReminders: undefined,
    archived: false,
  });
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const loginStatus = useLoginStatus();

  const list = useFetchHelper(
    async () =>
      SysServices.http.user.list(paging.page, paging.pageSize, {
        ...filters,
        search: search.used,
        rolesEnum: role,
      }),
    "Users"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
      others: {
        archived: false,
        allowLogIn: undefined,
        allowReminders: undefined,
        userTypeId: undefined,
        userTypeName: undefined,
        role: undefined,
      },
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
        setIsArchived(filters.others?.archived);
        setIsAllowLogIn(filters.others?.allowLogIn);
        setIsAllowReminders(filters.others?.allowReminders);
        setFilters({
          archived: filters.others?.archived,
          allowLogIn: filters.others?.allowLogIn,
          allowReminders: filters.others?.allowReminders,
          userTypeId: filters.others?.userTypeId,
          userTypeName: filters.others?.userTypeName,
        });
        setRole(filters.others?.role);
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
          others: {
            archived: isArchived,
            allowLogIn: isAllowLogIn,
            allowReminders: isAllowReminders,
            userTypeId: filters.userTypeId,
            userTypeName: filters.userTypeName,
            role: role,
          },
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, role, filters, pageFilters.ready]);

  const roles = useFetchHelper(
    () => SysServices.http.genericEnumLookup.getRolesEnums(),
    "Roles"
  );

  useEffect(() => {
    roles.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [itemUserTypes, setItemUserTypes] = useState([] as any[]);
  const [isLoadingUsers, setIsUserLoading] = useState(false);
  const refUser = React.createRef<any>();
  const handleSearchUsers = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setItemUserTypes([]);
      setIsUserLoading(false);
      return;
    }
    setIsUserLoading(true);
    await SysServices.http.userType
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemUserTypes(options);
        setIsUserLoading(false);
      })
      .catch((err) => {
        setItemUserTypes([]);
        setIsUserLoading(false);
      });
  };

  const companySettings = useCompanySettings();
  const isFiltered = useMemo(() => {
    if (
      isAllowLogIn === undefined &&
      isAllowReminders === undefined &&
      isArchived === undefined
    ) {
      return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowLogIn, isAllowReminders, isArchived]);

  return (
    <div>
      <div className="mb-3">
        <h4>{companySettings?.userLabelPlural}</h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  id="user-search"
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-0 flex gap-10">
              <div className="flex-0">
                <button
                  className="btn btn-primary no-wrap"
                  type="button"
                  onClick={(e) => {
                    nav("/users/new");
                  }}
                >
                  Add {companySettings?.userLabelSingular}
                </button>
              </div>

              <div className="flex-0 flex gap-10">
                <div className="flex-0">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant={
                        isFiltered ? "outline-danger" : "outline-secondary"
                      }
                      disabled={list.status === FetchStatus.InProgress}
                      className="no-wrap"
                    >
                      <span className="me-2">
                        {/* Allow Login: {GetDisplayForBooleanFilter(isAllowLogin)} */}
                        Filter {isFiltered && <i className="fa fa-filter"></i>}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      {!loginStatus.isParentCompany && (
                        <>
                          <Dropdown.Header>Allow Reminders?</Dropdown.Header>
                          {BOOLEAN_FILTERS.map((opt) => (
                            <div
                              className="bg-hover px-3 py-0"
                              key={opt}
                              //active={opt === GetDisplayForBooleanFilter(isAllowReminder)}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (opt === "Yes") setIsAllowReminders(true);
                                if (opt === "No") setIsAllowReminders(false);
                                if (opt === "All")
                                  setIsAllowReminders(undefined);
                              }}
                            >
                              <i
                                className={`fa ${
                                  opt ===
                                  GetDisplayForBooleanFilter(isAllowReminders)
                                    ? "fa-check-circle-o"
                                    : "fa-circle-o"
                                } me-2`}
                              ></i>
                              {opt}
                            </div>
                          ))}
                          <Dropdown.Divider className="mb-0"></Dropdown.Divider>
                        </>
                      )}
                      <Dropdown.Header>Allow Login?</Dropdown.Header>
                      {BOOLEAN_FILTERS.map((opt) => (
                        <div
                          className="bg-hover px-3 py-0"
                          key={opt}
                          //active={opt === GetDisplayForBooleanFilter(isAllowLogin)}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (opt === "Yes") setIsAllowLogIn(true);
                            if (opt === "No") setIsAllowLogIn(false);
                            if (opt === "All") setIsAllowLogIn(undefined);
                          }}
                        >
                          <i
                            className={`fa ${
                              opt === GetDisplayForBooleanFilter(isAllowLogIn)
                                ? "fa-check-circle-o"
                                : "fa-circle-o"
                            } me-2`}
                          ></i>
                          {opt}
                        </div>
                      ))}
                      <Dropdown.Divider className="mb-0"></Dropdown.Divider>
                      <Dropdown.Header>Archived?</Dropdown.Header>
                      {BOOLEAN_FILTERS.map((opt) => (
                        <div
                          className="bg-hover px-3 py-0"
                          key={opt}
                          //active={opt === GetDisplayForBooleanFilter(isArchived)}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (opt === "Yes") setIsArchived(true);
                            if (opt === "No") setIsArchived(false);
                            if (opt === "All") setIsArchived(undefined);
                          }}
                        >
                          <i
                            className={`fa ${
                              opt === GetDisplayForBooleanFilter(isArchived)
                                ? "fa-check-circle-o"
                                : "fa-circle-o"
                            } me-2`}
                          ></i>
                          {opt}
                        </div>
                      ))}
                      <Dropdown.Divider></Dropdown.Divider>
                      <Dropdown.Item className="px-2 text-right bg-no-hover">
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary me-2"
                          onClick={(e) => {
                            setIsArchived(filters.archived);
                            setIsAllowLogIn(filters.allowLogIn);
                            setIsAllowReminders(filters.allowReminders);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary px-3"
                          onClick={(e) => {
                            pageChange(1, paging.pageSize);
                            setFilters((prev) => {
                              return {
                                ...prev,
                                archived: isArchived,
                                allowLogIn: isAllowLogIn,
                                allowReminders: isAllowReminders,
                              };
                            });
                          }}
                          disabled={
                            isArchived === filters.archived &&
                            isAllowLogIn === filters.allowLogIn &&
                            isAllowReminders === filters.allowReminders
                          }
                        >
                          Apply
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="flex-0">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    disabled={list.status === FetchStatus.InProgress}
                    className="no-wrap"
                  >
                    <span className="me-2">
                      Role:{" "}
                      {role === undefined
                        ? "All"
                        : roles.data?.find((r) => r.value === role)?.label}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      active={role === undefined}
                      onClick={() => {
                        setRole(undefined);
                        pageChange(1, paging.pageSize);
                      }}
                    >
                      All
                    </Dropdown.Item>
                    {roles?.data
                      ?.filter((r) => {
                        if (
                          [
                            SysModels.RolesEnum.AppAdmin,
                            SysModels.RolesEnum.SaaSAdmin,
                          ].includes(r.value || 0)
                        ) {
                          return loginStatus.isParentCompany;
                        }
                        return !loginStatus.isParentCompany;
                      })
                      ?.map((opt) => (
                        <Dropdown.Item
                          key={opt.value}
                          active={role === opt.value}
                          onClick={(e) => {
                            setRole(opt.value);
                            pageChange(1, paging.pageSize);
                          }}
                        >
                          {opt.label}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {!loginStatus.isParentCompany && (
              <div className="flex-1 flex gap-10">
                <AsyncTypeahead
                  id="typeahead-search-user-type"
                  labelKey="name"
                  renderMenuItemChildren={(option, props, index) => (
                    <TyeaheadItem {...itemUserTypes[index]}></TyeaheadItem>
                  )}
                  onSearch={handleSearchUsers}
                  onChange={(data) => {
                    if (data.length > 0) {
                      const item = data[0] as any;
                      setFilters((prev) => {
                        return {
                          ...prev,
                          userTypeId: item ? item.id : undefined,
                          userTypeName: item ? item.name : undefined,
                        };
                      });
                      pageChange(1, paging.pageSize);
                      (refUser.current as any)?.clear();
                    }
                  }}
                  searchText={"Searching..."}
                  isLoading={isLoadingUsers}
                  options={itemUserTypes}
                  placeholder="Filter by Group"
                  minLength={1}
                  delay={500}
                  useCache={false}
                  filterBy={() => true}
                  ref={refUser}
                />
                <div className="flex-0">
                  {filters.userTypeId && (
                    <div className="alert alert-sm alert-secondary m-0 p-2 flex flex-center">
                      <div
                        className="flex-1 pe-2 no-wrap"
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {filters.userTypeName}
                      </div>
                      <div>
                        <i
                          className="fa fa-times pointer"
                          onClick={(e) => {
                            setFilters((prev) => {
                              return {
                                ...prev,
                                userTypeId: undefined,
                                userTypeName: undefined,
                              };
                            });
                            pageChange(1, paging.pageSize);
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>User Timezone</th>
                    {!loginStatus.isParentCompany && (
                      <th className="text-center no-wrap">Allow Reminders</th>
                    )}
                    <th className="text-center no-wrap">Allow To Login</th>
                    <th className="text-center">Archived</th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={8}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list?.data?.users?.map((item) => (
                    <tr
                      key={item.id}
                      className="pointer"
                      onClick={(e) => {
                        nav(`/users/${item.userName}`);
                      }}
                    >
                      <td>{item.lastName}</td>
                      <td>{item.firstName}</td>
                      <td>{item.email}</td>
                      <td>{item.userName}</td>
                      <td>{item.userTimeZone}</td>
                      {!loginStatus.isParentCompany && (
                        <td className="text-center">
                          <YesNoChip yes={item.allowReminders} />
                        </td>
                      )}
                      <td className="text-center">
                        <YesNoChip yes={item.allowToLogin} />
                      </td>
                      <td className="text-center">
                        <YesNoChip yes={item.archived} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel={companySettings?.userLabelPlural}
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Users;
