import React, { useEffect, useMemo, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import SwitchButton from "../components/SwitchButton";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import commonService from "../services/CommonService";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import FieldDialog from "./FieldDialog";
import TyeaheadItem from "../components/TyeaheadItem";
import ActivityLogs from "../components/ActivityLogs";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import {
  useActiveRole,
  useLoadedTab,
  useLoginStatus,
} from "../stores/SystemStore";
import { Tab, Tabs } from "react-bootstrap";
import ImportExportDefinitions from "./ImportExportDefinitions";
import ReminderEntry from "./ReminderEntry";

function Template(props: any) {
  const loginStatus = useLoginStatus();
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<SysModels.EntityTemplateOutputDto>({
    name: "",
    description: "",
    active: true,
  });

  const [origOrder, setOrigOrder] = useState<
    SysModels.EntityTemplateFieldOutputDto[]
  >([]);

  const current = useFetchHelper(
    async () =>
      loginStatus.hasRole("TemplateManager")
        ? SysServices.http.entityTemplate.get(id || "")
        : SysServices.http.entityTemplate.getEntityTemplateForReminder(
            id || ""
          ),
    "Template"
  );

  const allowFullTemplate = useFetchHelper(
    async () => SysServices.http.entityTemplate.allowFullTemplate(),
    "Allow"
  );

  const systemField = useFetchHelper(
    async () => SysServices.http.entityField.finishReminderTrigger(),
    "System Field"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);

    const newModel = {
      ...model,
      fields: (model.fields || []).map((f) => {
        return {
          ...f,
          entityFieldId: f.id,
          isFieldRequired: f.isFieldRequired,
        };
      }),
    };

    if (newModel.fullTemplate) {
      newModel.userTemplate = false;
    } else {
      newModel.showFiles = false;
      newModel.showLinks = false;
      newModel.showOtherEntities = false;
    }

    newModel.allowReminders = false; // #554 - Always 'False' for now

    if (id && id !== "new") {
      await SysServices.http.entityTemplate
        .update(id, newModel)
        .then((data) => {
          toastStore.showToast("Template Saved", "success");
          current.setDataAndComplete({
            ...model,
            ...data,
          });
          setModel({
            ...model,
            ...data,
          });
          setOrigOrder(data.fields || []);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.entityTemplate
        .create(newModel)
        .then((data) => {
          toastStore.showToast("Template Saved", "success");
          current.setDataAndComplete({
            ...model,
            ...data,
          });
          nav(`/templates/${data.id}`);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (loginStatus.hasRole("TemplateManager")) {
      allowFullTemplate.getData();
    } else {
      allowFullTemplate.setDataAndComplete(false);
    }
    if (!current.data?.id && id && id !== "new") {
      current.getData();
    }
    systemField.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (systemField.data && systemField.status === FetchStatus.Complete) {
      if (
        id === "new" &&
        !model.fields?.find((f) => f.id === systemField.data?.id)
      ) {
        setModel((prev) => {
          return {
            ...prev,
            fields: [
              ...(prev.fields || []),
              {
                id: systemField.data?.id,
                name: systemField.data?.name,
                sequence: 1,
              },
            ],
          };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemField.status]);

  useEffect(() => {
    if (allowFullTemplate.status === FetchStatus.Complete && id === "new") {
      setModel((prev) => {
        return {
          ...prev,
          fullTemplate: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowFullTemplate.status]);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel({ ...current.data });
      setOrigOrder(current.data.fields || []);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/templates");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [itemFields, setItemFields] = useState([] as any[]);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const refFields = React.createRef<any>();
  const handleSearchFields = async (query: string) => {
    let focus = false;
    if ((query || "").trim() === "*") {
      focus = true;
      (refFields.current as any)?.clear();
    } else if ((query || "").trim().length < 3) {
      setItemFields([]);
      setIsLoadingFields(false);
      return;
    }
    setIsLoadingFields(true);
    await SysServices.http.entityField
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemFields(options);
        setIsLoadingFields(false);
        if (focus) {
          commonService.focusInput("typeaheadSearchInput");
        }
      })
      .catch((err) => {
        setItemFields([]);
        setIsLoadingFields(false);
      });
  };

  const [itemCategs, setItemCategs] = useState([] as any[]);
  const [isCategLoading, setIsCategLoading] = useState(false);
  const refCateg = React.createRef<any>();
  const handleSearchCategs = async (query: string) => {
    let focus = false;
    if ((query || "").trim() === "*") {
      focus = true;
      (refCateg.current as any)?.clear();
    } else if ((query || "").trim().length < 3) {
      setItemCategs([]);
      setIsCategLoading(false);
      return;
    }
    setIsCategLoading(true);
    await SysServices.http.templateCategory
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemCategs(options);
        setIsCategLoading(false);
        if (focus) {
          commonService.focusInput("typeaheadSearchCategoryInput");
        }
      })
      .catch((err) => {
        setItemCategs([]);
        setIsCategLoading(false);
      });
  };

  const [dragging, setDragging] = useState<number>();
  const [dragOver, setDragOver] = useState<number>();

  useEffect(() => {
    if (
      model.id &&
      model.fields?.length &&
      model.fields?.length === origOrder.length
    ) {
      if (
        JSON.stringify(
          model.fields.map((x) => {
            return {
              sequence: x.sequence,
              id: x.id,
            };
          })
        ) !==
        JSON.stringify(
          origOrder.map((x) => {
            return {
              sequence: x.sequence,
              id: x.id,
            };
          })
        )
      ) {
        SysServices.http.entityTemplate
          .updateSequence(
            model.id,
            model.fields.map((f) => {
              return {
                entityFieldId: f.id,
                sequence: f.sequence,
              };
            })
          )
          .then((data) => {
            setOrigOrder(model.fields || []);
            toastStore.showToast("Sequence Updated", "success");
          })
          .catch((err) => {
            toastStore.showError("Failed Updating Sequence", err);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.fields]);

  const [delField, setDelField] = useState<number>();
  const [addField, setAddField] = useState(false);
  const activeRole = useActiveRole();
  const tabs = useLoadedTab(
    activeRole === "ReminderManager" ? ["Reminders"] : ["Detail"]
  );

  const [confirmNewOptions, setConfirmNewOptions] = useState(false);
  const canBeUpdatedOrDeleted = useMemo(() => {
    return !model.id || (!!model.id && !!model.canBeUpdatedOrDeleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <div className="tabbed-page">
      <ConfirmDialog
        show={showDel}
        title="Delete Template"
        message="Do you really want to delete this Template?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.entityTemplate
              .delete(id || "")
              .then((data) => {
                toastStore.showToast("Template Deleted", "success");
                nav("/templates");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Template", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        show={confirmNewOptions}
        title="Confirm Options"
        message="You turned on some settings on an already in use template. Note that you will not be able to turn it off again once saved. Do you want to continue?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            save();
          }
          setConfirmNewOptions(false);
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        show={!!delField}
        title="Delete Field from Template"
        message="Do you really want to delete this Field from this Template?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            const isNew = model.fields?.find(
              (f) => f.id === delField && !f.uniqueFieldName
            );
            if (isNew) {
              setModel((prev) => {
                return {
                  ...prev,
                  fields: prev.fields?.filter((f) => f.id !== delField),
                };
              });
              setDelField(undefined);
            } else {
              setDeleting(true);
              Promise.all([
                SysServices.http.entityTemplate.deleteField(
                  model.id || "",
                  delField || 0
                ),
                SysServices.http.entityTemplate.update(model.id || "", {
                  ...model,
                  fields: (model.fields || [])
                    .filter((f) => f.id !== delField)
                    .map((f, i) => {
                      return {
                        ...f,
                        entityFieldId: f.id,
                        sequence: i + 1,
                      };
                    }),
                }),
              ])
                .then((data) => {
                  toastStore.showToast("Field Deleted", "success");
                  setModel({
                    ...model,
                    ...data[1],
                  });
                  current.setData(data[1]);
                })
                .catch((err) => {
                  toastStore.showError("Failed Deleting Field", err);
                })
                .finally(() => {
                  setDeleting(false);
                  setDelField(undefined);
                });
            }
          } else {
            setDelField(undefined);
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      {addField && (
        <FieldDialog
          close={(data) => {
            if (data) {
              setModel((prev) => {
                return {
                  ...prev,
                  fields: [
                    ...(prev.fields || []),
                    {
                      id: data.id,
                      name: data.name,
                      sequence: (prev.fields?.length || 0) + 1,
                      isFieldRequired: data.isFieldRequired,
                    },
                  ],
                };
              });
            }
            setAddField(false);
          }}
        ></FieldDialog>
      )}

      {loginStatus.hasRole("TemplateManager") ? (
        <h4>{id === "new" ? "Add" : "Edit"} Template</h4>
      ) : (
        <h4>View Template</h4>
      )}

      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          {(allowFullTemplate.status === FetchStatus.InProgress ||
            current.status === FetchStatus.InProgress) && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}

          {allowFullTemplate.status === FetchStatus.Complete &&
            (current.status === FetchStatus.Complete || id === "new") && (
              <div className="flex flex-row flex-wrap" style={{ gap: "20px" }}>
                <div
                  className="mb-4"
                  style={{ maxWidth: "400px", width: "100%" }}
                >
                  <div className="pt-2">
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Name</label>
                      </div>
                      <InputWithSimilaritySearch
                        placeholder="Template Name"
                        autoFocus={true}
                        currentId={model.id}
                        value={model.name || ""}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              name: val,
                            };
                          });
                        }}
                        request={() =>
                          SysServices.http.entityTemplate.typeAhead({
                            search: model.name || "",
                          })
                        }
                        readOnly={!loginStatus.hasRole("TemplateManager")}
                      ></InputWithSimilaritySearch>
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label>Description</label>
                      </div>
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        value={model.description}
                        rows={3}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              description: e.target.value,
                            };
                          });
                        }}
                        readOnly={!loginStatus.hasRole("TemplateManager")}
                      />
                    </div>

                    {allowFullTemplate.data === true && (
                      <div className="mb-2">
                        <SwitchButton
                          uncheckedLabel="Full Template"
                          checked={model.fullTemplate || false}
                          onChange={(val) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                fullTemplate: val || false,
                              };
                            });
                          }}
                          disabled={!canBeUpdatedOrDeleted}
                        ></SwitchButton>
                      </div>
                    )}
                    {(!allowFullTemplate.data ||
                      (allowFullTemplate.data === true &&
                        !model.fullTemplate)) && (
                      <div className="mb-2">
                        <SwitchButton
                          uncheckedLabel="User Template"
                          checked={model.userTemplate || false}
                          onChange={(val) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                userTemplate: val || false,
                              };
                            });
                          }}
                          disabled={
                            !canBeUpdatedOrDeleted ||
                            !loginStatus.hasRole("TemplateManager")
                          }
                        ></SwitchButton>
                      </div>
                    )}
                    {/* <div>
                      <SwitchButton
                        uncheckedLabel="Allow Reminders"
                        checked={model.allowReminders || false}
                        disabled={!canBeUpdatedOrDeleted}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              allowReminders: val || false,
                            };
                          });
                        }}
                      ></SwitchButton>
                    </div> */}

                    <div
                      className={`mt-2 ${
                        model.fullTemplate ? "display-none" : ""
                      }`}
                    >
                      <div className="mb-1">
                        <label>Category</label>
                      </div>
                      {model.templateCategoryId ? (
                        <div className="flex flex-row alert alert-sm alert-secondary p-2">
                          <span className="flex-1 pe-2">
                            {model.templateCategoryName}
                          </span>
                          <span>
                            <i
                              title="Remove Category"
                              className="fa fa-times pointer"
                              onClick={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    templateCategoryName: null as any,
                                    templateCategoryId: null as any,
                                  };
                                });
                              }}
                            ></i>
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-row">
                          <div className="flex-1">
                            <AsyncTypeahead
                              id="typeaheadSearchCategoryInput"
                              labelKey="name"
                              renderMenuItemChildren={(
                                option,
                                props,
                                index
                              ) => (
                                <TyeaheadItem
                                  {...itemCategs[index]}
                                ></TyeaheadItem>
                              )}
                              onSearch={handleSearchCategs}
                              onChange={(data) => {
                                if (data.length > 0) {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      templateCategoryName: (data[0] as any)
                                        .name,
                                      templateCategoryId: (data[0] as any).id,
                                    };
                                  });
                                  (refCateg.current as any)?.clear();
                                }
                              }}
                              searchText={"Searching..."}
                              isLoading={isCategLoading}
                              options={itemCategs}
                              placeholder="Search Categories"
                              minLength={0}
                              delay={500}
                              useCache={true}
                              filterBy={() => true}
                              ref={refCateg}
                            />
                            <HiddenInputRequiredRef
                              id="typeaheadSearchCategoryInput"
                              value={""}
                              placeholder="Search"
                              onFocus={() => {
                                refCateg.current?.focus();
                              }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={(e) => {
                                //setAddField(true);
                                handleSearchCategs("*");
                              }}
                              title="Show All"
                            >
                              <i className="fa fa-list-ul"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    {!!current.data?.id && (
                      <div className="mt-2 mb-2">
                        <SwitchButton
                          uncheckedLabel="Active"
                          checked={model.active || false}
                          onChange={(val) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                active: val || false,
                              };
                            });
                          }}
                        ></SwitchButton>
                      </div>
                    )}
                  </div>

                  <div
                    className={`mt-4 pt-2 ${
                      loginStatus.hasRole("TemplateManager")
                        ? ""
                        : "display-none"
                    }`}
                  >
                    <button
                      className="btn btn-sm btn-primary float-right"
                      type="button"
                      onClick={(e) => {
                        if (
                          !!current.data?.id &&
                          model.fullTemplate &&
                          !model.canBeUpdatedOrDeleted &&
                          ((model.showFiles && !current.data.showFiles) ||
                            (model.showLinks && !current.data.showLinks) ||
                            (model.showOtherEntities &&
                              !current.data.showOtherEntities))
                        ) {
                          setConfirmNewOptions(true);
                        } else {
                          save();
                        }
                      }}
                      disabled={saving || deleting}
                    >
                      {saving ? "Saving..." : "Submit"}
                    </button>
                    <button
                      className="btn btn-sm btn-secondary me-2 float-right"
                      type="button"
                      onClick={(e) => {
                        nav("/templates");
                      }}
                      disabled={saving || deleting}
                    >
                      Cancel
                    </button>

                    {model.id && (
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={(e) => {
                          setShowDel(true);
                        }}
                        disabled={
                          !model.canBeUpdatedOrDeleted || saving || deleting
                        }
                      >
                        {deleting ? "Deleting..." : "Delete"}
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  {canBeUpdatedOrDeleted ? (
                    <>
                      <div className="pt-2 mb-1">
                        <label className="required-label">Fields</label>
                      </div>
                      <div
                        className={`flex flex-row col-sm-12 col-md-8 col-lg-6 mb-3 ${
                          loginStatus.hasRole("TemplateManager")
                            ? ""
                            : "display-none"
                        }`}
                      >
                        <div className="flex-1">
                          <AsyncTypeahead
                            id="typeahead-search-entity-fields"
                            labelKey="name"
                            onSearch={handleSearchFields}
                            renderMenuItemChildren={(option, props, index) => (
                              <TyeaheadItem
                                {...itemFields[index]}
                              ></TyeaheadItem>
                            )}
                            onChange={(data) => {
                              if (data.length > 0) {
                                if (
                                  model.fields?.find(
                                    (f) =>
                                      Number(f.id) ===
                                      Number((data[0] as any).id)
                                  )
                                ) {
                                  toastStore.showToast(
                                    "Field aleady exist",
                                    "warning"
                                  );
                                } else {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      fields: [
                                        ...(prev.fields || []),
                                        {
                                          id: Number((data[0] as any).id),
                                          name: (data[0] as any).name,
                                          sequence:
                                            (prev.fields?.length || 0) + 1,
                                        },
                                      ],
                                    };
                                  });
                                  (refFields.current as any)?.clear();
                                }
                              }
                            }}
                            searchText={"Searching..."}
                            isLoading={isLoadingFields}
                            options={itemFields}
                            placeholder="Search Fields"
                            minLength={0}
                            delay={500}
                            useCache={true}
                            filterBy={() => true}
                            ref={refFields}
                          />
                          <HiddenInputRequiredRef
                            id="typeaheadSearchInput"
                            value={""}
                            placeholder="Search"
                            onFocus={() => {
                              refFields.current?.focus();
                            }}
                          />
                        </div>
                        <div>
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={(e) => {
                              //setAddField(true);
                              handleSearchFields("*");
                            }}
                            title="Show All"
                          >
                            <i className="fa fa-list-ul"></i>
                          </button>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={(e) => {
                              setAddField(true);
                            }}
                            title="Add Field"
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="pt-2 mb-1">
                      <label>Fields</label>
                    </div>
                  )}
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "100px" }}>Sequence</th>
                        <th>Name</th>
                        <th className="w-10 text-center">Required?</th>
                        {loginStatus.hasRole("TemplateManager") &&
                          canBeUpdatedOrDeleted && (
                            <th style={{ width: "50px" }}></th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {model.fields?.map((field) => (
                        <tr
                          key={`${field.id}-${field.sequence}-${
                            field.uniqueFieldName || ""
                          }`}
                          className={`${
                            dragging === field.id ? "dragging" : ""
                          } ${
                            dragOver === field.id
                              ? ((model.fields || []).find(
                                  (x) => x.id === dragOver
                                )?.sequence || 0) >
                                ((model.fields || []).find(
                                  (x) => x.id === dragging
                                )?.sequence || 0)
                                ? "dragover"
                                : "dragover-2"
                              : ""
                          }`}
                          style={
                            loginStatus.hasRole("TemplateManager")
                              ? { cursor: "move" }
                              : {}
                          }
                          draggable={loginStatus.hasRole("TemplateManager")}
                          onDragStart={(e) => {
                            setDragging(field.id);
                          }}
                          onDragEnd={(e) => {
                            setDragging(undefined);
                            setDragOver(undefined);
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                            if (dragging === field.id) {
                              return;
                            }
                            setDragOver(field.id);
                          }}
                          onDrop={(e) => {
                            if (dragging) {
                              const adj =
                                (field.sequence || 0) >
                                ((model.fields || []).find(
                                  (x) => x.id === dragging
                                )?.sequence || 0)
                                  ? 0.1
                                  : -0.1;

                              setModel((prev) => {
                                return {
                                  ...prev,
                                  fields: (prev.fields || [])
                                    .map((x) => {
                                      if (x.id === dragging) {
                                        return {
                                          ...x,
                                          sequence: (field.sequence || 0) + adj,
                                        };
                                      }
                                      return x;
                                    })
                                    .sort(
                                      commonService.sortByNumericProperty(
                                        "sequence"
                                      )
                                    )
                                    .map((x, i) => {
                                      return {
                                        ...x,
                                        sequence: i + 1,
                                      };
                                    }),
                                };
                              });

                              setDragging(undefined);
                              setDragOver(undefined);
                            }
                          }}
                        >
                          <td>
                            {field.uniqueFieldName && (
                              <>
                                {loginStatus.hasRole("TemplateManager") && (
                                  <i className="fa fa-bars me-2"></i>
                                )}
                                {field.sequence}
                              </>
                            )}
                            {!field.uniqueFieldName && (
                              <span className="chip-green">NEW</span>
                            )}
                          </td>
                          <td>{field.name}</td>
                          <td className="text-center">
                            {/* <YesNoChip yes={field.isFieldRequired} /> */}
                            <SwitchButton
                              checked={field.isFieldRequired || false}
                              onChange={(val) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    fields: (prev.fields || []).map((f) => {
                                      if (f.id === field.id) {
                                        return {
                                          ...f,
                                          isFieldRequired: val,
                                        };
                                      }
                                      return f;
                                    }),
                                  };
                                });
                              }}
                              disabled={!loginStatus.hasRole("TemplateManager")}
                            ></SwitchButton>
                          </td>
                          {loginStatus.hasRole("TemplateManager") &&
                            canBeUpdatedOrDeleted && (
                              <td className="text-center">
                                {!!(
                                  systemField.data?.id === field.id &&
                                  (model.allowReminders || !model.fullTemplate)
                                ) ? (
                                  <i className="fa fa-lock"></i>
                                ) : (
                                  <i
                                    title="Delete Field"
                                    className="fa fa-trash text-danger pointer"
                                    onClick={(e) => {
                                      setDelField(field.id);
                                    }}
                                  ></i>
                                )}
                              </td>
                            )}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {allowFullTemplate.data && model.fullTemplate && (
                    <>
                      <div className="pt-4 pb-2">Template Settings</div>
                      <div className="row">
                        {/* <div className="col-sm-12 col-md-6">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>User/Group who can create Entity</th>
                                <th className="text-center w-20">Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Managers</td>
                                <td className="text-center">3</td>
                              </tr>
                              <tr>
                                <td>Team Leads</td>
                                <td className="text-center">6</td>
                              </tr>
                              <tr>
                                <td>Juan dela Cruz</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Maria Lopez</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="pt-3 pb-2 text-right">
                            <button
                              className="btn btn-sm btn-primary me-2"
                              type="button"
                            >
                              Add User
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                            >
                              Add Group
                            </button>
                          </div>
                        </div> */}
                        <div className="col-sm-12 col-md-6">
                          <div>
                            <div className="alert alert-light">
                              <div className="pb-2">
                                <SwitchButton
                                  uncheckedLabel="Show Files Tab"
                                  checked={model.showFiles || false}
                                  disabled={
                                    !canBeUpdatedOrDeleted &&
                                    current.data?.showFiles
                                  }
                                  onChange={(val) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        showFiles: val || false,
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                              <div className="pb-2">
                                <SwitchButton
                                  uncheckedLabel="Show Links Tab"
                                  checked={model.showLinks || false}
                                  disabled={
                                    !canBeUpdatedOrDeleted &&
                                    current.data?.showLinks
                                  }
                                  onChange={(val) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        showLinks: val || false,
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                              <div className="pb-2">
                                <SwitchButton
                                  uncheckedLabel="Other Entities"
                                  checked={model.showOtherEntities || false}
                                  disabled={
                                    !canBeUpdatedOrDeleted &&
                                    current.data?.showOtherEntities
                                  }
                                  onChange={(val) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        showOtherEntities: val || false,
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
        </Tab>
        {id !== "new" && (
          <Tab eventKey="Reminders" title="Reminders">
            {tabs.loadedTabs.includes("Reminders") && (
              <div>
                <ReminderEntry templateId={id} />
              </div>
            )}
          </Tab>
        )}
        {id !== "new" &&
          loginStatus.hasRole("TemplateManager") &&
          !model.fullTemplate && (
            <Tab eventKey="ImportExport" title="Import/Export Definitions">
              {tabs.loadedTabs.includes("ImportExport") && (
                <div>
                  <ImportExportDefinitions
                    entityTemplateId={id || ""}
                    fields={model.fields || []}
                  />
                </div>
              )}
            </Tab>
          )}
        {id !== "new" && (
          <Tab eventKey="Logs" title="Logs">
            {tabs.loadedTabs.includes("Logs") && (
              <div>
                <ActivityLogs
                  type={SysModels.LogObjectTypeEnum.EntityTemplate}
                  stringId={model.id}
                  forTab={true}
                ></ActivityLogs>
              </div>
            )}
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Template;
