import React, { useEffect, useMemo, useState } from "react";
import "./App.scss";
import Layout from "./layout/Layout";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Toast from "./components/toast/Toast";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import SysServices from "./services";
import systemStore, {
  ILoginStatus,
  useCompanySettings,
  useErrorDialog,
  useLoginStatus,
  useSiteDown,
  useSwitchCompany,
} from "./stores/SystemStore";
import dayjs from "dayjs";
import { reaction } from "mobx";
import LoggedOut from "./pages/LoggedOut";
import menuActions, { IMenuAction } from "./layout/MenuActions";
import commonService from "./services/CommonService";
import CommonSpinner from "./components/CommonSpinner";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import FormModal from "./components/FormModal";
import { Modal } from "react-bootstrap";
import SysModels from "./models";
import SiteDownPage from "./pages/SiteDownPage";
import { FetchStatus, useFetchHelper } from "./services/FetchHelper";
import ConfirmDialog from "./components/ConfirmDialog";
import toastStore from "./stores/ToastStore";

const AccessDenied = () => (
  <div className="py-2 text-danger">
    <i className="fa fa-warning me-2"></i>Access Denied
  </div>
);

const AppRouter = (
  loginStatus: ILoginStatus & {
    hasRole: (role?: string) => boolean;
  }
) => {
  //console.log("AppRouter");
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/dashboard" />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/change-password" element={<ChangePassword />}></Route>
        {menuActions
          .getActions()
          //.sort(commonService.sortByStringProperty("label"))
          .map((action) => {
            if (
              action.roles?.length === 0 ||
              action.roles?.find((r) => loginStatus.hasRole(r))
            ) {
              if (!action.canAccess || action.canAccess()) {
                return (
                  <Route
                    key={action.url}
                    path={action.url}
                    element={<MenuActionComponent action={action} />}
                  ></Route>
                );
              } else {
                return (
                  <Route
                    key={action.url}
                    path={action.url}
                    element={<AccessDenied />}
                  ></Route>
                );
              }
            }
            return <React.Fragment key={action.url} />;
          })}
        <Route path="*" element={<Navigate to="/dashboard" />}></Route>
      </Route>
    )
  );
};

const LoginRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/logout" element={<LoggedOut />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/reset-password/:token" element={<ResetPassword />}></Route>
      <Route path="*" element={<Login />}></Route>
    </Route>
  )
);

const ChangePasswordRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="*" element={<ChangePassword must={true} />}></Route>
    </Route>
  )
);

const SiteDownRouter = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<SiteDownPage />}></Route>)
);

function App() {
  const ma = menuActions;
  const loginStatus = useLoginStatus();
  const errDialog = useErrorDialog();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const toggleLogin = () => {
    const login = SysServices.http.fetcher.getToken();
    if (login && login.token && login.refreshTokenExpiration) {
      systemStore.setAuthData(login);
      if (dayjs().isBefore(login.refreshTokenExpiration)) {
        setIsLoggedIn(true);

        const jwt = SysServices.common.parseJwt(login.token);
        //console.log(jwt);
        if (jwt && jwt.exp) {
          console.log(
            dayjs(jwt.exp * 1000)
              //.add(-29, "minute") //Quick Test
              .format("MMM DD, YYYY hh:mm:ss A")
          );
        }
      } else {
        SysServices.http.fetcher.clearToken();
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  const [tmoSystemMessage, setTmoSystemMessage] = useState(0);
  useEffect(() => {
    toggleLogin();
    window.addEventListener(
      "token_changed",
      (event) => {
        //console.log(event);
        toggleLogin();
      },
      false
    );

    const tmi = setInterval(() => {
      toggleLogin();
    }, 5000);

    const disposer = reaction(
      () => systemStore.tmoSystemMessage,
      (val, prevVal, r) => {
        setTmoSystemMessage(val);
      }
    );

    return () => {
      clearInterval(tmi);
      disposer();
      //clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ready, setReady] = useState(false);
  const [isDown, setIsDown] = useState(false);
  const siteDown = useSiteDown();
  const getSiteDown = async () => {
    await SysServices.http.siteDownMessage
      .getDisplay()
      .then((data) => {
        systemStore.setSiteDown(data);
        !ready && setTimeout(() => setReady(true), 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSiteDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmoSystemMessage]);

  const lastDashboard = useFetchHelper(
    async () => SysServices.http.userSetting.getLastDashboard(),
    "Last Dashboard"
  );

  useEffect(() => {
    if (ready) {
      if (isDown) {
        //do nothing...
      } else {
        if (
          loginStatus.loggedIn &&
          lastDashboard.status === FetchStatus.Default
        ) {
          if (
            !loginStatus.mustDoSomething?.changePassword &&
            !loginStatus.mustDoSomething?.need2faAuthentication
          ) {
            lastDashboard.getData();
          }
        }
        if (
          !loginStatus.loggedIn &&
          lastDashboard.status !== FetchStatus.Default
        ) {
          lastDashboard.reset();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, isDown, loginStatus]);

  useEffect(() => {
    if (lastDashboard.status === FetchStatus.Complete && lastDashboard.data) {
      if (loginStatus.hasRole(lastDashboard.data.setting)) {
        systemStore.setActiveRole(lastDashboard.data.setting as any);
      } else {
        const ax = ma.actions.find((a) => a.url === window.location.pathname);
        if (ax) {
          systemStore.setActiveRole(ax.roles[0]);
        } else {
          systemStore.setActiveRole(loginStatus.roles[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDashboard.status]);

  useEffect(() => {
    if (
      siteDown &&
      !commonService.isNullOrWhitespace(siteDown.message || "") &&
      siteDown.from &&
      siteDown.to &&
      dayjs().isAfter(siteDown.from) &&
      dayjs().isBefore(siteDown.to)
    ) {
      if (
        loginStatus.loggedIn &&
        (loginStatus.hasRole("AppAdmin") || loginStatus.hasRole("SaaSAdmin"))
      ) {
        setIsDown(false);
      } else {
        setIsDown(true);
      }
    } else {
      setIsDown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDown, loginStatus]);

  const swithCompany = useSwitchCompany();
  const companySettings = useCompanySettings();
  //const [appRouterId, setAppRouterId] = useState(commonService.getUniqueId());

  const appRouter = useMemo(() => {
    if (
      isLoggedIn &&
      loginStatus.loggedIn &&
      !loginStatus.mustDoSomething?.changePassword
    ) {
      //setAppRouterId(commonService.getUniqueId());
      return AppRouter(loginStatus);
    }
    return null;
  }, [
    loginStatus.loggedIn,
    loginStatus.mustDoSomething?.changePassword,
    loginStatus.companySwitched,
    companySettings,
    isLoggedIn,
  ]);

  return (
    <div className="App">
      {swithCompany.switching && (
        <CommonSpinner overlay={true} message="Switching..."></CommonSpinner>
      )}
      <ConfirmDialog
        show={swithCompany.ask}
        title="Switch Company"
        message={`Do you really want to switch to <strong>${swithCompany.companyName}</strong>?`}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            const id = swithCompany.companyId;
            systemStore.setSwitchingCompany(true);
            SysServices.http.myAccount
              .loginDifferentCompany(id)
              .then((data) => {
                SysServices.http.fetcher.storeToken(data);
                setTimeout(() => {
                  systemStore.setActiveRole("Admin");
                }, 100);
              })
              .catch((err) => {
                toastStore.showError("Failed Switching Company", err);
              })
              .finally(() => {
                systemStore.setSwitchingCompany(false);
              });
          }
          systemStore.setSwitchCompany(false);
        }}
      ></ConfirmDialog>

      <FormModal
        isOpen={errDialog.show}
        title={errDialog.title}
        close={() => {
          systemStore.setErrorDialog(undefined);
        }}
        submit={() => {
          systemStore.setErrorDialog(undefined);
        }}
        size="md"
        customBodyFooter={true}
      >
        <Modal.Body>
          <div className="flex flex-row flex-center gap-20">
            <div>
              {errDialog.severity ===
                SysModels.ErrorSeverityEnum.Information && (
                <i className="fa fa-info-circle fa-2x text-primary"></i>
              )}
              {errDialog.severity === SysModels.ErrorSeverityEnum.Error && (
                <i className="fa fa-exclamation-triangle fa-2x text-danger"></i>
              )}
              {errDialog.severity === SysModels.ErrorSeverityEnum.Warning && (
                <i className="fa fa-exclamation-triangle fa-2x text-warning"></i>
              )}
            </div>
            <div
              className="flex-1"
              dangerouslySetInnerHTML={{
                __html: errDialog.message,
              }}
            ></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={(e) => {
              systemStore.setErrorDialog(undefined);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </FormModal>

      <Toast />
      {/* <BrowserRouter> */}
      {isDown && (
        <>
          <RouterProvider router={SiteDownRouter}></RouterProvider>
        </>
      )}
      {ready && !isDown && (
        <>
          {!isLoggedIn && (
            <RouterProvider router={LoginRouter}></RouterProvider>
          )}

          {loginStatus.loggedIn &&
            loginStatus.mustDoSomething?.changePassword && (
              <RouterProvider router={ChangePasswordRouter}></RouterProvider>
            )}

          {loginStatus.loggedIn &&
            !loginStatus.mustDoSomething?.changePassword &&
            appRouter && <RouterProvider router={appRouter}></RouterProvider>}

          {isLoggedIn && !loginStatus.roles.length && (
            <div>
              <CommonSpinner
                overlay={true}
                message="Loading..."
              ></CommonSpinner>
            </div>
          )}
        </>
      )}
      {/* </BrowserRouter> */}
    </div>
  );
}

const MenuActionComponent = ({ action }: { action: IMenuAction }) => {
  return React.createElement(action.component, {});
};

export default App;
