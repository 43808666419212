import React, { useEffect, useMemo, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import CommonSpinner from "../components/CommonSpinner";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import SwitchButton from "../components/SwitchButton";
import commonService from "../services/CommonService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import {
  useCompanySettings,
  useLoadedTab,
  useLoginStatus,
} from "../stores/SystemStore";
import DateTimePicker from "../components/DateTimePicker";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import ActivityLogs from "../components/ActivityLogs";
import UserSubordinatesDialog from "./UserSubordinatesDialog";
import { Tab, Tabs } from "react-bootstrap";
import NotificationsTab from "../components/NotificationsTab";

function User(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<
    SysModels.UserOutputDto & { sendPasswordEmail?: boolean }
  >({
    firstName: "",
    lastName: "",
    userName: "",
    userTimeZone: "",
    allowReminders: false,
    allowToLogin: false,
    archived: false,
    roles: [],
    sendPasswordEmail: false,
  });

  const timezones = useFetchHelper(SysServices.http.timeZone.get, "Timezones");
  const current = useFetchHelper(
    async () => SysServices.http.user.get(id || ""),
    "User"
  );

  const validateForm = () => {
    const err = commonService.getFormErrors("userForm");
    if (err.length) {
      toastStore.showToast(err[0], "warning");
    }
    return err.length === 0;
  };

  const [saving, setSaving] = useState(false);
  const save = async () => {
    if (!validateForm()) return;

    if (model.email && !commonService.regexPatterns.email.test(model.email)) {
      toastStore.showToast("Invalid Email Format", "warning");
      return;
    }

    let currentRoles: any = model.roles?.map((r) => r.rolesEnum || 0) || [];
    // if (loginStatus.isParentCompany && !loginStatus.hasRole("AppAdmin")) {
    //   currentRoles = undefined;
    // }

    setSaving(true);
    if (id && id !== "new") {
      await SysServices.http.user
        .update(id as any, {
          ...model,
          roles: currentRoles,
          userTypeIds:
            model.userTypeForUserOutputDtos?.map((x) => x.id || 0) || [],
          fields: model.fields?.map((f) => {
            const val = getFiedlValues(f.entityFieldId);
            return {
              entityFieldId: f.entityFieldId,
              value: val === undefined ? (null as any) : val,
            };
          }),
        })
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.userLabelSingular} Saved`,
            "success"
          );
          current.getData();
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.userLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.user
        .create({
          ...model,
          roles: currentRoles,
          userTypeIds:
            model.userTypeForUserOutputDtos?.map((x) => x.id || 0) || [],
        })
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.userLabelSingular} Saved`,
            "success"
          );
          current.setDataAndComplete(data);
          nav(`/users/${data.userName}`);
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.userLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const roles = useFetchHelper(
    () => SysServices.http.genericEnumLookup.getRolesEnums(),
    "Roles"
  );

  useEffect(() => {
    if (!current.data?.id && id && id !== "new") {
      current.getData();
    }
    timezones.getData();
    roles.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [itemUserTypes, setItemUserTypes] = useState([] as any[]);
  const [isLoadingUsers, setIsUserLoading] = useState(false);
  const refUser = React.createRef<any>();
  const handleSearchUsers = async (query: string) => {
    let focus = false;
    if ((query || "").trim() === "*") {
      focus = true;
      (refUser.current as any)?.clear();
    } else if ((query || "").trim().length < 3) {
      setItemUserTypes([]);
      setIsUserLoading(false);
      return;
    }
    setIsUserLoading(true);
    await SysServices.http.userType
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemUserTypes(options);
        setIsUserLoading(false);
        if (focus) {
          commonService.focusInput("typeaheadSearchUserTypeInput");
        }
      })
      .catch((err) => {
        setItemUserTypes([]);
        setIsUserLoading(false);
      });
  };

  const refTz = React.createRef<any>();

  const loginStatus = useLoginStatus();
  const [sendingPw, setSendingPw] = useState(false);

  const setFieldValue = (fieldId: any, value: any) => {
    setModel((prev) => {
      return {
        ...prev,
        fields: (prev.fields || []).find((f) => f.entityFieldId === fieldId)
          ? [
              ...(prev.fields || []).map((f) => {
                if (f.entityFieldId === fieldId) {
                  return {
                    ...f,
                    value: value,
                  };
                }
                return f;
              }),
            ]
          : [
              ...(prev.fields || []),
              {
                entityFieldId: fieldId,
                value: value,
              },
            ],
      };
    });
  };

  const getFiedlValues = (fieldId: any) => {
    return (model.fields || []).find((f) => f.entityFieldId === fieldId)?.value;
  };

  const roleArrayList = useMemo(() => {
    return [
      loginStatus.isParentCompany && loginStatus.hasRole("AppAdmin")
        ? [
            ...(roles.data || []).filter(
              (r) =>
                r.value === SysModels.RolesEnum.AppAdmin ||
                r.value === SysModels.RolesEnum.SaaSAdmin
            ),
          ]
        : [],
      loginStatus.isParentCompany
        ? []
        : [
            ...(roles.data || []).filter(
              (r) =>
                !(
                  r.value === SysModels.RolesEnum.AppAdmin ||
                  r.value === SysModels.RolesEnum.SaaSAdmin
                )
            ),
          ],
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.data]);

  const fields = useMemo(() => {
    const list: SysModels.EntityFieldFilledOutputDto[] = [];
    (model.fields || []).forEach((fld) => {
      if (!list.find((f) => f.entityFieldId === fld.entityFieldId)) {
        list.push(fld);
      }
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.data]);

  const isReadOnly = useMemo(() => {
    if (id === "new") return false;
    if (loginStatus.isParentCompany) {
      if (loginStatus.hasRole("AppAdmin")) {
        return false;
      } else {
        return !!(model.roles || []).find(
          (r) => r.rolesEnum === SysModels.RolesEnum.AppAdmin
        );
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.roles, loginStatus]);

  const [showSubs, setShowSubs] = useState<{
    userTypeId: number;
    userTypeName: string;
  }>();

  const tabs = useLoadedTab(["Detail"]);
  const companySettings = useCompanySettings();

  return (
    <div className="tabbed-page">
      <h4>
        {id === "new" ? "Add" : isReadOnly ? "View" : "Edit"}{" "}
        {companySettings?.userLabelSingular}
      </h4>

      {!!showSubs && !!model.id && (
        <UserSubordinatesDialog
          userTypeId={showSubs.userTypeId}
          userTypeName={showSubs.userTypeName}
          managerId={model.id}
          close={(done) => {
            setShowSubs(undefined);
          }}
        ></UserSubordinatesDialog>
      )}

      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          {current.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {(current.status === FetchStatus.Complete || id === "new") && (
            <div id="userForm">
              <div className="row">
                <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
                  <div
                    className="flex flex-row flex-wrap"
                    style={{ gap: "20px" }}
                  >
                    <div className="flex flex-row flex-1">
                      <div className="pt-2 flex-1">
                        <div className="mb-2">
                          <div className="mb-1">
                            <label className="required-label">First Name</label>
                          </div>
                          <InputWithSimilaritySearch
                            placeholder="First Name"
                            autoFocus={true}
                            currentId={model.id}
                            value={model.firstName || ""}
                            className="required"
                            onChange={(val) => {
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  firstName: val,
                                };
                              });
                            }}
                            request={() =>
                              SysServices.http.user.typeAhead({
                                search: model.firstName || "",
                              })
                            }
                            readOnly={isReadOnly}
                          ></InputWithSimilaritySearch>
                        </div>
                        <div className="mb-2">
                          <div className="mb-1">
                            <label className="required-label">Last Name</label>
                          </div>
                          <InputWithSimilaritySearch
                            placeholder="Last Name"
                            className="required"
                            autoFocus={false}
                            currentId={model.id}
                            value={model.lastName || ""}
                            onChange={(val) => {
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  lastName: val,
                                };
                              });
                            }}
                            request={() =>
                              SysServices.http.user.typeAhead({
                                search: model.lastName || "",
                              })
                            }
                            readOnly={isReadOnly}
                          ></InputWithSimilaritySearch>
                        </div>
                        <div className="mb-2">
                          <div className="mb-1">
                            <label className="required-label">Email</label>
                          </div>
                          <input
                            className="form-control required"
                            placeholder="Email"
                            value={model.email}
                            autoComplete="new-password"
                            onChange={(e) => {
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  email: e.target.value,
                                };
                              });
                            }}
                            readOnly={isReadOnly}
                          />
                        </div>
                        <div className="mb-2">
                          <div className="mb-1">
                            <label className="required-label">Username</label>
                          </div>
                          <input
                            className="form-control required"
                            placeholder="Username"
                            value={model.userName}
                            autoComplete="new-password"
                            onChange={(e) => {
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  userName: e.target.value,
                                };
                              });
                            }}
                            readOnly={!!model.id || isReadOnly}
                          />
                        </div>

                        <div className="mb-2">
                          <div className="mb-1">
                            <label className="required-label">Timezone</label>
                          </div>
                          {!isReadOnly && (
                            <div className="mb-2">
                              <AsyncTypeahead
                                id="typeahead-search-timezone"
                                labelKey="label"
                                onSearch={() => {}}
                                onChange={(data) => {
                                  if (data.length > 0) {
                                    const item = data[0] as any;
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        userTimeZone: item.label,
                                      };
                                    });
                                    (refTz.current as any)?.clear();
                                  }
                                }}
                                searchText={"Searching..."}
                                isLoading={false}
                                options={timezones.data || []}
                                placeholder="Search Timezone"
                                minLength={0}
                                delay={500}
                                useCache={true}
                                //filterBy={() => true}
                                ref={refTz}
                              />
                              <HiddenInputRequiredRef
                                value={model.userTimeZone}
                                placeholder="Timezone"
                                onFocus={() => refTz.current?.focus()}
                              />
                            </div>
                          )}
                          {model.userTimeZone && (
                            <div className="alert alert-sm alert-secondary p-2">
                              {
                                timezones.data?.find(
                                  (tz) => tz.label === model.userTimeZone
                                )?.label
                              }
                            </div>
                          )}
                        </div>
                        {!loginStatus.isParentCompany && (
                          <div className="pt-2 mb-2">
                            <div className="mb-1">
                              <SwitchButton
                                uncheckedLabel="Allow Reminders?"
                                checked={model.allowReminders || false}
                                onChange={(val) => {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      allowReminders: val || false,
                                    };
                                  });
                                }}
                              ></SwitchButton>
                            </div>
                          </div>
                        )}
                        <div className="mb-2">
                          <div className="mb-1">
                            <SwitchButton
                              uncheckedLabel="Allow To Login?"
                              checked={model.allowToLogin || false}
                              onChange={(val) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    allowToLogin: val || false,
                                  };
                                });
                              }}
                              disabled={
                                isReadOnly ||
                                (id !== "new" &&
                                  loginStatus.userName === model.userName)
                              }
                            ></SwitchButton>
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className="mb-2">
                            <SwitchButton
                              uncheckedLabel="Archived"
                              checked={model.archived || false}
                              onChange={(val) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    archived: val || false,
                                  };
                                });
                              }}
                              disabled={
                                isReadOnly ||
                                (id !== "new" &&
                                  loginStatus.userName === model.userName)
                              }
                            ></SwitchButton>
                          </div>
                        </div>

                        {model.id && !!model.fields?.length && (
                          <>
                            <div>
                              <hr />
                            </div>
                            {fields.map((field) => (
                              <div key={field.entityFieldId} className="mb-2">
                                <div className="mb-1">
                                  <label
                                    className={
                                      field.isFieldRequired
                                        ? "required-label"
                                        : ""
                                    }
                                  >
                                    {field.name}
                                  </label>
                                </div>
                                <div className="input-with-help-icon">
                                  {!commonService.isNullOrEmpty(
                                    field.description || ""
                                  ) && (
                                    <i
                                      className="fa fa-info-circle text-primary pointer"
                                      title={field.description}
                                    ></i>
                                  )}
                                  {field.fieldType ===
                                    SysModels.WF1FieldType.Date && (
                                    <>
                                      <div id={`field${field.entityFieldId}`}>
                                        <DateTimePicker
                                          dateOnly={true}
                                          data={getFiedlValues(
                                            field.entityFieldId
                                          )}
                                          onChange={(data) => {
                                            setFieldValue(
                                              field.entityFieldId,
                                              data || ""
                                            );
                                          }}
                                          disabled={props.viewOnly}
                                        />
                                      </div>
                                      {field.isFieldRequired && (
                                        <HiddenInputRequiredRef
                                          value={getFiedlValues(
                                            field.entityFieldId
                                          )}
                                          placeholder={field.name || ""}
                                          onFocus={() => {
                                            document
                                              .getElementById(
                                                `field${field.entityFieldId}`
                                              )
                                              ?.querySelector("input")
                                              ?.focus();
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                  {field.fieldType ===
                                    SysModels.WF1FieldType.Text && (
                                    <input
                                      type="text"
                                      className={`form-control ${
                                        field.isFieldRequired ? "required" : ""
                                      }`}
                                      placeholder={field.name}
                                      value={getFiedlValues(
                                        field.entityFieldId
                                      )}
                                      onChange={(e) => {
                                        setFieldValue(
                                          field.entityFieldId,
                                          e.target.value
                                        );
                                      }}
                                      readOnly={props.viewOnly}
                                    />
                                  )}
                                  {field.fieldType ===
                                    SysModels.WF1FieldType.TextArea && (
                                    <textarea
                                      className={`form-control ${
                                        field.isFieldRequired ? "required" : ""
                                      }`}
                                      placeholder={field.name}
                                      rows={3}
                                      value={getFiedlValues(
                                        field.entityFieldId
                                      )}
                                      onChange={(e) => {
                                        setFieldValue(
                                          field.entityFieldId,
                                          e.target.value
                                        );
                                      }}
                                      readOnly={props.viewOnly}
                                    />
                                  )}
                                  {field.fieldType ===
                                    SysModels.WF1FieldType.Number && (
                                    <input
                                      type="number"
                                      className={`form-control ${
                                        field.isFieldRequired ? "required" : ""
                                      }`}
                                      placeholder={field.name}
                                      value={getFiedlValues(
                                        field.entityFieldId
                                      )}
                                      onChange={(e) => {
                                        setFieldValue(
                                          field.entityFieldId,
                                          e.target.value
                                        );
                                      }}
                                      readOnly={props.viewOnly}
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}

                        {id === "new" && (
                          <>
                            <hr />
                            <div className="mb-2">
                              <div className="mb-2">
                                <SwitchButton
                                  uncheckedLabel="Send Password via Email"
                                  checked={model.sendPasswordEmail || false}
                                  onChange={(val) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        sendPasswordEmail: val || false,
                                      };
                                    });
                                  }}
                                  disabled={
                                    id !== "new" &&
                                    loginStatus.userName === model.userName
                                  }
                                ></SwitchButton>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="pt-2 flex-1">
                      <div className="mb-2">
                        {roleArrayList.find((r) => r.length > 0) && (
                          <div className="mb-1">
                            <label>Roles</label>
                          </div>
                        )}

                        {roles.status === FetchStatus.InProgress && (
                          <div>Loading...</div>
                        )}

                        {roleArrayList
                          .filter((grp) => grp.length > 0)
                          .map((grp, i) => (
                            <React.Fragment key={i}>
                              {grp.length > 0 && (
                                <div className="alert alert-sm alert-secondary p-2 px-3 pe-4">
                                  <div className="pt-2"></div>
                                  {grp
                                    ?.filter((role) => {
                                      if (
                                        role.value ===
                                          SysModels.RolesEnum.AppAdmin ||
                                        role.value ===
                                          SysModels.RolesEnum.SaaSAdmin
                                      ) {
                                        //Only Show AppAdmin/SaaSAdmin if User isalready an AppAdmin
                                        return loginStatus.hasRole("AppAdmin");
                                      }
                                      if (
                                        role.value === SysModels.RolesEnum.Admin
                                      ) {
                                        //Only Show Admin if User isalready an Admin
                                        return loginStatus.hasRole("Admin");
                                      }
                                      return true;
                                    })
                                    ?.map((role) => {
                                      const myRecord =
                                        loginStatus.userName === model.userName;
                                      const selected = !!model.roles?.find(
                                        (rr) => rr.rolesEnum === role.value
                                      );

                                      return (
                                        <div key={role.value} className="mb-2">
                                          <SwitchButton
                                            disabled={
                                              myRecord &&
                                              (role.value ===
                                                SysModels.RolesEnum.Admin ||
                                                role.value ===
                                                  SysModels.RolesEnum.AppAdmin)
                                            }
                                            uncheckedLabel={role.label}
                                            checked={selected}
                                            onChange={(data) => {
                                              if (
                                                model.roles?.find(
                                                  (rr) =>
                                                    rr.rolesEnum === role.value
                                                )
                                              ) {
                                                setModel((prev) => {
                                                  return {
                                                    ...prev,
                                                    roles: prev.roles?.filter(
                                                      (r) =>
                                                        r.rolesEnum !==
                                                        role.value
                                                    ),
                                                  };
                                                });
                                              } else {
                                                setModel((prev) => {
                                                  return {
                                                    ...prev,
                                                    roles: [
                                                      ...(prev.roles || []),
                                                      {
                                                        roleName: role.label,
                                                        rolesEnum: role.value,
                                                      },
                                                    ],
                                                  };
                                                });
                                              }
                                            }}
                                          ></SwitchButton>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                      {!loginStatus.isParentCompany && (
                        <div>
                          <div className="mb-1">
                            <label>Groups</label>
                          </div>
                          <div className="mb-2 flex flex-row">
                            <div className="flex-1">
                              <AsyncTypeahead
                                id="typeahead-search-user-type"
                                labelKey="name"
                                renderMenuItemChildren={(
                                  option,
                                  props,
                                  index
                                ) => (
                                  <TyeaheadItem
                                    {...itemUserTypes[index]}
                                  ></TyeaheadItem>
                                )}
                                onSearch={handleSearchUsers}
                                onChange={(data) => {
                                  if (data.length > 0) {
                                    const item = data[0] as any;
                                    if (
                                      model.userTypeForUserOutputDtos?.find(
                                        (x) => Number(x.id) === Number(item.id)
                                      )
                                    ) {
                                      toastStore.showToast(
                                        `'${item.name}' is already in the list.`,
                                        "warning"
                                      );
                                      return;
                                    }

                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        userTypeForUserOutputDtos: [
                                          ...(prev.userTypeForUserOutputDtos ||
                                            []),
                                          {
                                            id: item.id,
                                            name: item.name,
                                          },
                                        ],
                                      };
                                    });
                                    (refUser.current as any)?.clear();
                                  }
                                }}
                                searchText={"Searching..."}
                                isLoading={isLoadingUsers}
                                options={itemUserTypes}
                                placeholder="Search Groups"
                                minLength={0}
                                delay={500}
                                useCache={false}
                                filterBy={() => true}
                                ref={refUser}
                              />
                              <HiddenInputRequiredRef
                                id="typeaheadSearchUserTypeInput"
                                value="*"
                                placeholder="Search"
                                onFocus={() => {
                                  refUser.current?.focus();
                                }}
                              />
                            </div>
                            <div>
                              <div>
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={(e) => {
                                    handleSearchUsers("*");
                                  }}
                                  title="Show All"
                                >
                                  <i className="fa fa-list-ul"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          {model.userTypeForUserOutputDtos?.map((ut, i) => (
                            <div
                              key={ut.id}
                              className="alert alert-sm alert-secondary p-2 flex flex-center mb-1"
                              title={ut.name}
                            >
                              <div
                                className="flex-1 pe-2"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  wordBreak: "break-word",
                                }}
                              >
                                {ut.name}
                              </div>
                              <div className="px-1">
                                {ut.canManageUsers && (
                                  <span
                                    className="pointer"
                                    title="Subordinates"
                                    onClick={(e) => {
                                      if (ut.id && ut.name) {
                                        setShowSubs({
                                          userTypeId: ut.id,
                                          userTypeName: ut.name,
                                        });
                                      }
                                    }}
                                  >
                                    <i className="fa fa-users me-1 text-primary"></i>
                                    <small className="mx-1 me-2 text-primary">
                                      <strong>{ut.numberOfUsers}</strong>
                                    </small>
                                  </span>
                                )}

                                <i
                                  className="fa fa-times text-danger pointer"
                                  onClick={(e) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        userTypeForUserOutputDtos: [
                                          ...(
                                            prev.userTypeForUserOutputDtos || []
                                          ).filter(
                                            (x) =>
                                              Number(x.id) !== Number(ut.id)
                                          ),
                                        ],
                                      };
                                    });
                                  }}
                                ></i>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="mt-4"
                    style={{ maxWidth: "400px", width: "100%" }}
                  >
                    <div className="pt-2">
                      {id !== "new" && !isReadOnly && (
                        <button
                          className="btn btn-sm btn-primary"
                          type="button"
                          onClick={(e) => {
                            if (model.userName) {
                              setSendingPw(true);
                              SysServices.http.user
                                .sendPasswordViaEmail(model.userName)
                                .then((data) => {
                                  toastStore.showToast(
                                    "Password Sent",
                                    "success"
                                  );
                                })
                                .catch((err) => {
                                  toastStore.showError(
                                    "Failed Sending Password",
                                    err
                                  );
                                })
                                .finally(() => {
                                  setSendingPw(false);
                                });
                            }
                          }}
                          disabled={sendingPw}
                        >
                          <i className="fa fa-envelope me-2"></i>
                          {sendingPw ? "Sending..." : "Send Password"}
                        </button>
                      )}

                      {!isReadOnly && (
                        <button
                          className="btn btn-sm btn-primary float-right"
                          type="button"
                          onClick={(e) => {
                            save();
                          }}
                          disabled={saving}
                        >
                          {saving ? "Saving..." : "Submit"}
                        </button>
                      )}
                      <button
                        className="btn btn-sm btn-secondary me-2 float-right"
                        type="button"
                        onClick={(e) => {
                          nav("/users");
                        }}
                        disabled={saving}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-4 col-xl-6"></div>
              </div>
            </div>
          )}
        </Tab>
        {id !== "new" && (
          <Tab eventKey="Notifications" title="Notifications">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Notifications") && (
                <NotificationsTab list={[]}></NotificationsTab>
              )}
            </div>
          </Tab>
        )}
        {id !== "new" && (
          <Tab eventKey="Logs" title="Logs">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Logs") && (
                <ActivityLogs
                  type={SysModels.LogObjectTypeEnum.User}
                  stringId={model.id}
                  forTab={true}
                ></ActivityLogs>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default User;
