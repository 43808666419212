import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import ConfirmDialog from "../components/ConfirmDialog";
import toastStore from "../stores/ToastStore";
import FileDialog from "./FileDialog";
import SysModels from "../models";

function FilesTab(props: {
  entityId: string;
  canAddEdit: boolean;
  dialogOpen: (open: boolean) => void;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(async () => {
    return SysServices.http.entityFile.list(paging.page, paging.pageSize, {
      search: search.used,
      entityId: props.entityId || "",
    });
  }, "Files");

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        props.entityId && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  const [showDelete, setShowDelete] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const [showEdit, setShowEdit] = useState<SysModels.EntityFileOutputDto>();

  return (
    <>
      {(showDialog || !!showEdit) && (
        <FileDialog
          entityId={props.entityId}
          forEdit={showEdit}
          close={(data) => {
            setShowDialog(false);
            setShowEdit(undefined);
            props.dialogOpen(false);
            if (data) {
              list.getData();
            }
          }}
        ></FileDialog>
      )}
      <ConfirmDialog
        show={!!showDelete}
        title="Delete File"
        message="Do you really want to delete this file?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes" && showDelete) {
            setDeleting(true);
            SysServices.http.entityFile
              .delete(showDelete)
              .then((data) => {
                toastStore.showToast("File Deleted", "success");
                list.getData();
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting File", err);
              })
              .finally(() => {
                setShowDelete(undefined);
                props.dialogOpen(false);
                setDeleting(false);
              });
          } else {
            setShowDelete(undefined);
            props.dialogOpen(false);
          }
        }}
      ></ConfirmDialog>

      <div className="flex flex-wrap gap-10 mb-3">
        <div className="flex-0" style={{ maxWidth: "100%" }}>
          <div className="input-group search-box">
            <input
              autoFocus={true}
              className="form-control"
              type="text"
              placeholder="Search..."
              value={search.typed}
              onChange={(e) => {
                setSearch((data) => {
                  return {
                    ...data,
                    typed: e.target.value,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }
              }}
            ></input>
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-search"></i>
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    return { typed: "", used: "" };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        {props.canAddEdit && (
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => {
                setShowDialog(true);
                props.dialogOpen(true);
              }}
            >
              Add File
            </button>
          </div>
        )}
        <div className="flex-1"></div>
      </div>

      <div className="p-0" style={{ position: "relative" }}>
        {list.status === FetchStatus.InProgress && (
          <CommonSpinner message="Loading..."></CommonSpinner>
        )}
        {deleting && (
          <CommonSpinner message="Deleting..." overlay={true}></CommonSpinner>
        )}
        {list.status !== FetchStatus.InProgress && (
          <>
            <div>
              <table className="table table-bordered table-hover mt-2">
                <thead>
                  <tr>
                    <th className="w-40">File Name</th>
                    <th className="w-60">File Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={3}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list.data?.entityFileOutputDtos?.map((row) => (
                    <tr
                      key={row.id}
                      className="pointer"
                      onClick={(e) => {
                        setShowEdit(row);
                        props.dialogOpen(true);
                      }}
                    >
                      <td>{row.userFileName}</td>
                      <td>{row.description}</td>
                      <td className="no-wrap">
                        {props.canAddEdit && (
                          <i
                            className="fa fa-pencil text-primary pointer mx-2"
                            title="Edit"
                          ></i>
                        )}
                        <i
                          className="fa fa-trash text-danger pointer mx-2"
                          title="Delete"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowDelete(row.id);
                            props.dialogOpen(true);
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="hide-on-print">
              <Pagination
                length={list.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                showingOfWhatLabel={"Links"}
                sizes={[10, 15, 25, 50, 100]}
              ></Pagination>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default FilesTab;
