import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import SwitchButton from "../components/SwitchButton";
import { Tab, Tabs } from "react-bootstrap";
import { useCompanySettings, useLoadedTab } from "../stores/SystemStore";
import ActivityLogs from "../components/ActivityLogs";

function UserType(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<SysModels.UserTypeOutputDto>({
    name: "",
    description: "",
    canManageUsers: false,
  });

  const current = useFetchHelper(
    async () => SysServices.http.userType.get(id || ""),
    "Group"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    if (id && id !== "new") {
      await SysServices.http.userType
        .update(id as any, {
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Group Saved", "success");
          current.setDataAndComplete(data);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Group", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.userType
        .create({
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Group Saved", "success");
          current.setDataAndComplete(data);
          nav(`/user-types/${data.id}`, { replace: true });
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Group", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (!current.data?.id && id && id !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/user-types");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const tabs = useLoadedTab(["Detail"]);
  const companySettings = useCompanySettings();

  return (
    <div className="tabbed-page">
      <ConfirmDialog
        show={showDel}
        title="Delete Group"
        message="Do you really want to delete this Group?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.userType
              .delete(id as any)
              .then((data) => {
                toastStore.showToast("Group Deleted", "success");
                nav("/user-types");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Group", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <h4>{id === "new" ? "Add" : "Edit"} Group</h4>

      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          {current.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}

          {(current.status === FetchStatus.Complete || id === "new") && (
            <div style={{ maxWidth: "400px", width: "100%" }}>
              <div className="pt-2">
                <div className="mb-2">
                  <div className="mb-1">
                    <label className="required-label">Name</label>
                  </div>
                  <InputWithSimilaritySearch
                    placeholder="Group"
                    autoFocus={true}
                    currentId={model.id}
                    value={model.name || ""}
                    onChange={(val) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          name: val,
                        };
                      });
                    }}
                    request={() =>
                      SysServices.http.userType.typeAhead({
                        search: model.name || "",
                      })
                    }
                  ></InputWithSimilaritySearch>
                </div>
                <div className="mb-2">
                  <div className="mb-1">
                    <label>Description</label>
                  </div>
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    value={model.description}
                    rows={3}
                    onChange={(e) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          description: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div className="mb-2">
                  <div className="mb-2">
                    <SwitchButton
                      uncheckedLabel="Can Manage Users?"
                      checked={model.canManageUsers || false}
                      onChange={(val) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            canManageUsers: val || false,
                          };
                        });
                      }}
                    ></SwitchButton>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="mb-4">
                    <SwitchButton
                      uncheckedLabel="Allow To Get Any Reminder"
                      checked={model.canReceiveAnyReminder || false}
                      onChange={(val) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            canReceiveAnyReminder: val || false,
                          };
                        });
                      }}
                    ></SwitchButton>
                  </div>
                </div>
              </div>

              <div className="pt-2">
                <button
                  className="btn btn-sm btn-primary float-right"
                  type="button"
                  onClick={(e) => {
                    save();
                  }}
                  disabled={saving || deleting}
                >
                  {saving ? "Saving..." : "Submit"}
                </button>
                <button
                  className="btn btn-sm btn-secondary me-2 float-right"
                  type="button"
                  onClick={(e) => {
                    nav("/user-types");
                  }}
                  disabled={saving || deleting}
                >
                  Cancel
                </button>

                {model.id && (
                  <button
                    className="btn btn-sm btn-danger"
                    type="button"
                    onClick={(e) => {
                      setShowDel(true);
                    }}
                    disabled={!model.canBeDeleted || saving || deleting}
                  >
                    {deleting ? "Deleting..." : "Delete"}
                  </button>
                )}
              </div>
            </div>
          )}
        </Tab>

        {id !== "new" && (
          <Tab eventKey="Logs" title="Logs">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Logs") && (
                <ActivityLogs
                  type={SysModels.LogObjectTypeEnum.UserType}
                  numberId={model.id}
                  forTab={true}
                ></ActivityLogs>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default UserType;
