import React, { useState } from "react";
import FormModal from "../components/FormModal";
import Entity from "./Entity";
import { useCompanySettings, useErrorDialog } from "../stores/SystemStore";

function FullEntityDialog(props: {
  close: (changed?: boolean) => void;
  entityId?: string;
  templateId?: string;
}) {
  const companySettings = useCompanySettings();
  const [moveBehind, setMoveBehind] = useState(false);
  const errDialog = useErrorDialog();

  return (
    <FormModal
      title={`${props.entityId ? "Edit" : "Add"} ${
        companySettings?.entityLabelSingular
      }`}
      size="xl"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {}}
      customBodyFooter={true}
      moveBehind={errDialog.show || moveBehind}
    >
      <div className="p-4 pt-2">
        <Entity
          fullTemplate={true}
          idForDialog={props.entityId}
          templateIdForDialog={props.templateId}
          closeDialog={(changed?: boolean) => props.close(changed)}
          modalOpened={(open) => {
            setMoveBehind(open);
          }}
        ></Entity>
      </div>
    </FormModal>
  );
}

export default FullEntityDialog;
