export interface CompanyBaseModelDto {
    companyName?: string;
    active?: boolean;
    timeZone?: string;
}
export interface CompanyOutputDto extends CompanyBaseModelDto {
    id?: string;
    archived?: boolean;
    numberOfFullTemplates?: number;
    companyAddress?: AddressDto;
    companySettingsDto?: CompanySettingsDto;
}
export interface AddressDto {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
    mainContactName?: string;
    mainContactPhone?: string;
    mainContactEmail?: string;
}
export interface CompanySettingsDto {
    entityLabelSingular?: string;
    entityLabelPlural?: string;
    userLabelSingular?: string;
    userLabelPlural?: string;
    allowFullTemplates?: boolean;
    allowCommentEditDeletes?: boolean;
    editTimeLimit?: number;
}
export interface HttpErrorOutputDto {
    errors?: ErrorOutputDto[];
    statusCode?: number;
    errorSeverityEnum?: ErrorSeverityEnum;
}
export interface ErrorOutputDto {
    fieldName?: string;
    message?: string;
}
export enum ErrorSeverityEnum {
    Information = 0,
    Warning = 1,
    Error = 2,
}
export interface GridOutputBaseDto {
    totalRecords?: number;
}
export interface CompanyGridDto extends GridOutputBaseDto {
    companyDetailItemDtos?: CompanyDetailItemDto[];
}
export interface CompanyDetailItemDto extends CompanyBaseModelDto {
    id?: string;
    archived?: boolean;
    companyAddress?: AddressDto;
}
export interface SearchInputBaseDto {
    search?: string;
}
export interface CompanySearchDto extends SearchInputBaseDto {
    active?: boolean;
    archived?: boolean;
}
export interface CompanyCreateDto extends CompanyBaseModelDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    password?: string;
    companyAddress?: AddressDto;
    allowFullTemplates?: boolean;
    allowCommentEditDeletes?: boolean;
    editTimeLimit?: number;
}
export interface CompanyUpdateDto extends CompanyBaseModelDto {
    archived?: boolean;
    companyAddress?: AddressDto;
    allowFullTemplates?: boolean;
    allowCommentEditDeletes?: boolean;
    editTimeLimit?: number;
}
export interface CompanyAdminOutputDto extends CompanyBaseModelDto {
    companyAddress?: AddressDto;
    companySettingsDto?: CompanySettingsDto;
    companyLogo?: string;
    maxFileSize?: number;
}
export interface CompanyNameLogoSettingsDto {
    companyName?: string;
    companySettingsDto?: CompanySettingsDto;
    companyLogo?: string;
}
export interface CompanyUpdateAdminDto {
    companyAddress?: AddressDto;
}
export interface ConfigSettingDto {
    area?: string;
    section?: string;
    value?: string;
}
export interface EntityCommentCreateDto {
    text?: string;
    entityId?: string;
    parentCommentId?: string;
}
export interface EntityCommentUpdateDto {
    text?: string;
}
export interface EntityCommentGridOutputDto extends GridOutputBaseDto {
    entityCommentGridItemOutputDtos?: EntityCommentGridItemOutputDto[];
}
export interface EntityCommentGridItemOutputDto {
    commentId?: string;
    entityId?: string;
    parentCommentId?: string;
    hasChildren?: boolean;
    isNew?: boolean;
    dateCreated?: Date;
    text?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
}
export interface EntityCommentBaseSearchDto {
    entityId?: string;
}
export interface EntityCommentParentListDto extends EntityCommentBaseSearchDto {
}
export interface EntityCommentChildrenListDto extends EntityCommentBaseSearchDto {
    parentCommentId?: string;
}
export interface EntityCommentSearchInputDto extends SearchInputBaseDto {
    entityId?: string;
}
export interface EntityCommentFindSearchResultOutputDto {
    parents?: EntityCommentGridOutputDto;
    parentPage?: number;
    parentCommentIndex?: number;
    children?: EntityCommentGridOutputDto;
    childPage?: number;
    childCommentIndex?: number;
}
export interface EntityBaseDto {
    entityTemplateId?: string;
    userId?: string;
}
export interface EntityOutputBaseDto extends EntityBaseDto {
    id?: string;
    entityTemplateName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    fields?: EntityFieldInGridDto[];
}
export interface EntityOutputDto extends EntityOutputBaseDto {
    fields?: EntityFieldFilledOutputDto[];
    notificationOutputDetailDtos?: NotificationOutputDetailDto[];
}
export interface EntityFieldFilledInDto {
    entityFieldId?: number;
    value?: string;
}
export interface EntityFieldFilledOutputDto extends EntityFieldFilledInDto {
    name?: string;
    fieldType?: WF1FieldType;
    description?: string;
    sequence?: number;
    isFieldRequired?: boolean;
}
export enum WF1FieldType {
    Text = 0,
    TextArea = 1,
    Number = 2,
    Date = 3,
}
export interface NotificationOutputDetailDto {
    id?: string;
    userTypeName?: string;
    emailAddress?: string;
    dateToSend?: Date;
    dateSent?: Date;
    canBeSent?: boolean;
    errorDate?: Date;
    errorMessage?: string;
    emailSentTo?: string;
    atTimeOfAssignment?: boolean;
    fieldName?: string;
    dayBefore?: number;
    dayAfter?: number;
}
export interface EntityFieldInGridDto {
    name?: string;
    fieldType?: WF1FieldType;
    sequence?: number;
    value?: string;
}
export interface EntityGridOutputDto extends GridOutputBaseDto {
    entities?: EntityOutputBaseDto[];
}
export interface EntitySearchInputDto extends SearchInputBaseDto {
}
export interface EntityInputDto extends EntityBaseDto {
    fields?: EntityFieldFilledInDto[];
}
export interface EntityUpdateDto {
    fields?: EntityFieldFilledInDto[];
}
export interface EntityFieldBaseDto {
    name?: string;
    description?: string;
    fieldType?: WF1FieldType;
    isFieldRequired?: boolean;
}
export interface EntityFieldOutputBaseDto extends EntityFieldBaseDto {
    id?: number;
}
export interface EntityFieldOutputDto extends EntityFieldOutputBaseDto {
    canBeUpdateOrDeleted?: boolean;
}
export interface EntityFieldGridOutputDto extends GridOutputBaseDto {
    fields?: EntityFieldOutputBaseDto[];
}
export interface EntityFieldSearchInputDto extends SearchInputBaseDto {
}
export interface TypeAheadOutputDto {
    id?: string;
    label?: string;
    description?: string;
}
export interface TypeAheadInputDto {
    search?: string;
}
export interface EntityFieldTypeAheadDateOnly extends TypeAheadInputDto {
    templateId?: string;
}
export interface EntityFieldInputDto extends EntityFieldBaseDto {
}
export interface EntityFileGridOutputDto extends GridOutputBaseDto {
    entityFileOutputDtos?: EntityFileOutputDto[];
}
export interface EntityFileOutputDto {
    id?: string;
    userFileName?: string;
    fileName?: string;
    description?: string;
    entityId?: string;
}
export interface EntityFileSearchInputDto extends SearchInputBaseDto {
    entityId?: string;
}
export interface EntityLinkOutputDto {
    id?: string;
    entityId?: string;
    url?: string;
    urlText?: string;
    description?: string;
}
export interface EntityLinkGridOutputDto extends GridOutputBaseDto {
    entityLinkOutputDtos?: EntityLinkOutputDto[];
}
export interface EntityLinkSearchDto extends SearchInputBaseDto {
    entityId?: string;
}
export interface EntityLinkInputDto {
    entityId?: string;
    url?: string;
    urlText?: string;
    description?: string;
}
export interface EntityLinkUpdateDto {
    url?: string;
    urlText?: string;
    description?: string;
}
export interface EntityTemplateBaseDto {
    name?: string;
    description?: string;
    templateCategoryId?: string;
    userTemplate?: boolean;
    active?: boolean;
    fullTemplate?: boolean;
    showFiles?: boolean;
    showLinks?: boolean;
    showOtherEntities?: boolean;
    allowReminders?: boolean;
}
export interface EntityTemplateOutputBaseDto extends EntityTemplateBaseDto {
    id?: string;
    templateCategoryName?: string;
}
export interface EntityTemplateOutputDto extends EntityTemplateOutputBaseDto {
    canBeUpdatedOrDeleted?: boolean;
    uniqueFieldName?: string;
    fields?: EntityTemplateFieldOutputDto[];
}
export interface EntityTemplateFieldOutputDto extends EntityFieldOutputBaseDto {
    sequence?: number;
    uniqueFieldName?: string;
    isFieldRequired?: boolean;
}
export interface EntityTemplateGridOutputDto extends GridOutputBaseDto {
    templates?: EntityTemplateOutputBaseDto[];
}
export interface EntityTemplateSearchInputDto extends SearchInputBaseDto {
    templateCategoryId?: string;
}
export interface EntityTemplateGridWithReminderOutputDto extends GridOutputBaseDto {
    templates?: EntityTemplateOutputWithReminderDto[];
}
export interface EntityTemplateOutputWithReminderDto extends EntityTemplateBaseDto {
    id?: string;
    templateCategoryName?: string;
    reminderSetting?: ReminderSettingDto;
}
export interface ReminderSettingDto {
    reminderSettingDetailDtos?: ReminderSettingDetailDto[];
}
export interface ReminderSettingDetailDto {
    reminderSettingDetailId?: string;
    notifyEntityUser?: boolean;
    userTypeId?: number;
    otherUserEmailAddresses?: string;
    atTimeOfAssignment?: boolean;
    fieldId?: number;
    daysBefore?: string;
    daysAfter?: string;
}
export interface EntityTemplateReminderSearchInputDto extends SearchInputBaseDto {
    templateCategoryId?: string;
    reminderListFilterEnum?: ReminderListFilterEnum;
}
export enum ReminderListFilterEnum {
    All = 0,
    HasReminders = 1,
    DoesNotHaveReminders = 2,
}
export interface EntityTemplateInputDto extends EntityTemplateBaseDto {
    fields?: EntityFieldSequenceDto[];
}
export interface EntityFieldSequenceDto {
    entityFieldId?: number;
    sequence?: number;
    isFieldRequired?: boolean;
}
export interface MultipleTemplatesForCategoryListOutputDto {
    multipleTemplatesForCategoryOutputDtos?: MultipleTemplatesForCategoryOutputDto[];
}
export interface MultipleTemplatesForCategoryOutputDto {
    id?: string;
    name?: string;
    wasSuccess?: boolean;
}
export interface MultipleTemplatesForCategoryInputDto {
    categoryId?: string;
    templateIds?: string[];
}
export interface TemplateCategorySearchOutputDto {
    id?: string;
    name?: string;
    existingCategoryName?: string;
    hasReminders?: boolean;
}
export interface FullTemplateEntityInsertDto {
    entityTemplateId?: string;
    fields?: EntityFieldFilledInDto[];
    participants?: FullTemplateEntityParticipantDto[];
}
export interface FullTemplateEntityParticipantDto {
    userId?: string;
    access?: FullTemplateEntityAccess;
}
export enum FullTemplateEntityAccess {
    Admin = 1,
    Contributor = 2,
    Reader = 3,
    NoAccess = 4,
}
export interface FullTemplateEntityGridOutputDto extends GridOutputBaseDto {
    fullTemplateEntityGridItemOutputDtos?: FullTemplateEntityGridItemOutputDto[];
}
export interface FullTemplateEntityBaseOutputDto {
    id?: string;
    entityTemplateName?: string;
    numberOfParticipants?: number;
    currentUserAccess?: FullTemplateEntityAccess;
}
export interface FullTemplateEntityGridItemOutputDto extends FullTemplateEntityBaseOutputDto {
    fields?: EntityFieldInGridDto[];
    numberOfParticipants?: number;
    newChanges?: boolean;
}
export interface FullTemplateEntitySearchInputDto extends SearchInputBaseDto {
}
export interface FullTemplateEntityOutputDto extends FullTemplateEntityBaseOutputDto {
    fields?: EntityFieldFilledOutputDto[];
}
export interface FullTemplateEntityUpdateDto {
    fields?: EntityFieldFilledInDto[];
}
export interface FullTemplateParticipantsUpdateDto {
    participants?: FullTemplateEntityParticipantDto[];
}
export interface FullTemplateParticipantGridOutputDto extends GridOutputBaseDto {
    fullTemplateParticipantGridItemOutputDtos?: FullTemplateParticipantGridItemOutputDto[];
}
export interface FullTemplateParticipantGridItemOutputDto {
    userId?: string;
    firstName?: string;
    lastName?: string;
    access?: FullTemplateEntityAccess;
}
export interface FulTemplateParticipantSearchDto extends SearchInputBaseDto {
    entityId?: string;
    participantStatus?: ParticipantStatusEnum;
}
export enum ParticipantStatusEnum {
    Active = 1,
    Inactive = 2,
    All = 3,
}
export interface LookupIntDto {
    label?: string;
    value?: number;
}
export interface GlobalSiteMessageDisplayDto {
    message?: string;
    severity?: ErrorSeverityEnum;
}
export interface GlobalSiteMessageDto {
    severityEnum?: ErrorSeverityEnum;
    from?: Date;
    to?: Date;
    message?: string;
}
export interface HowToOutputDraftDto {
    id?: string;
    roles?: RolesEnum[];
    sequence?: number;
    titleDraft?: string;
    bodyDraft?: string;
    publishedOn?: Date;
    title?: string;
    body?: string;
    displayOnHomePage?: boolean;
}
export enum RolesEnum {
    AppAdmin = 0,
    Admin = 1,
    User = 2,
    UserManager = 3,
    ReminderManager = 4,
    TemplateManager = 5,
    SaaSAdmin = 6,
}
export interface HowToHomePageInputDto {
    role?: RolesEnum;
}
export interface HowToInputDto {
    roles?: RolesEnum[];
    sequence?: number;
    titleDraft?: string;
    bodyDraft?: string;
    displayOnHomePage?: boolean;
}
export interface HowToGridOutputDto extends GridOutputBaseDto {
    howToOutputDtos?: HowToOutputPublishedDto[];
}
export interface HowToOutputPublishedDto {
    id?: string;
    roles?: RolesEnum[];
    sequence?: number;
    title?: string;
    body?: string;
    titleDraft?: string;
    bodyDraft?: string;
    publishedOn?: Date;
    displayOnHomePage?: boolean;
}
export interface HowToSearchDto extends SearchInputBaseDto {
    role?: RolesEnum;
}
export interface ImportExportInputBaseDto {
    name?: string;
    description?: string;
    importExportColumDefinitionDtos?: ImportExportColumDefinitionDto[];
}
export interface ImportExportOutputDto extends ImportExportInputBaseDto {
    id?: string;
    entityTemplateName?: string;
    isImportDefinition?: boolean;
}
export interface ImportExportColumDefinitionDto {
    columnName?: string;
    fieldId?: number;
    dateFormat?: string;
}
export interface ImportExportGridDto extends GridOutputBaseDto {
    importExportGridItemDtos?: ImportExportGridItemDto[];
}
export interface ImportExportGridItemDto {
    id?: string;
    name?: string;
    description?: string;
    isImportDefinition?: boolean;
}
export interface ImportExportSearchInputDto extends SearchInputBaseDto {
    entityTemplateId?: string;
}
export interface ImportExportInputCreateDto extends ImportExportInputBaseDto {
    entityTemplateId?: string;
    isImportDefinition?: boolean;
}
export interface ImportExportInputUpdateDto extends ImportExportInputBaseDto {
}
export interface UserInitialInputDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    password?: string;
    userTimeZone?: string;
}
export interface LogGridDto extends GridOutputBaseDto {
    logOutputDtos?: LogOutputDto[];
}
export interface LogOutputDto {
    id?: string;
    objectType?: LogObjectTypeEnum;
    objectIdGuid?: string;
    objectIdLong?: number;
    changeType?: LogChangeTypeEnum;
    userId?: string;
    eventTime?: Date;
    changes?: string;
    userName?: string;
    companyId?: string;
    companyName?: string;
}
export enum LogObjectTypeEnum {
    User = 1,
    EntityField = 2,
    EntityTemplate = 3,
    TemplateCategory = 4,
    Entity = 5,
    Company = 6,
    UserType = 7,
}
export enum LogChangeTypeEnum {
    Create = 1,
    Update = 2,
    Delete = 3,
}
export interface LogSearchDto {
    objectType?: LogObjectTypeEnum;
    objectIdGuid?: string;
    objectIdLong?: number;
    changeType?: LogChangeTypeEnum;
    userId?: string;
    orderBy?: OrderByEnum;
    companyId?: string;
}
export interface LogSearchCompanyWideDto extends LogSearchDto {
}
export enum OrderByEnum {
    Ascending = 0,
    Descending = 1,
}
export interface SubordinatesListOutputDto {
    userTypeId?: number;
    userTypeName?: string;
    managerId?: string;
    managerName?: string;
    subordinateOutputDtos?: SubordinateOutputDto[];
}
export interface SubordinateOutputDto {
    subordinateId?: string;
    name?: string;
}
export interface SubordinateSuccessOutputDto {
    subordinateIdSuccessDtos?: SubordinateIdSuccessDto[];
}
export interface SubordinateIdSuccessDto {
    subordinateId?: string;
    wasSuccess?: boolean;
}
export interface SubordinateBaseDto {
    userTypeId?: number;
    managerId?: string;
}
export interface SubordinateInputDto extends SubordinateBaseDto {
    subordinateIdDtos?: SubordinateIdDto[];
}
export interface SubordinateIdDto {
    subordinateId?: string;
}
export interface TokenDto {
    token?: string;
    refreshToken?: string;
    refreshTokenExpiration?: Date;
    sessionId?: string;
}
export interface UserLoginDto {
    userName?: string;
    password?: string;
}
export interface RefreshTokenDto {
    refreshToken?: string;
    userName?: string;
    sessionId?: string;
}
export interface ChangePasswordRequestDto {
    currentPassword?: string;
    newPassword?: string;
}
export interface ForgotPasswordDto {
    userName?: string;
}
export interface ResetPasswordDto {
    userName?: string;
    resetPasswordToken?: string;
    password?: string;
}
export interface ReleaseOutputDto {
    id?: string;
    releaseDate?: Date;
    versionNumber?: string;
    description?: string;
    public?: boolean;
}
export interface ReleaseInputDto {
    releaseDate?: Date;
    versionNumber?: string;
    description?: string;
    public?: boolean;
}
export interface ReleaseGridOutputDto extends GridOutputBaseDto {
    releaseOutputDtos?: ReleaseOutputDto[];
}
export interface ReleaseSearchInputDto extends SearchInputBaseDto {
}
export interface ReminderSettingOutputDto {
    objectName?: string;
    userTemplate?: boolean;
    reminderSettingDetailOutputDtos?: ReminderSettingDetailIOutputDto[];
}
export interface ReminderSettingDetailIOutputDto extends ReminderSettingDetailDto {
    fieldName?: string;
    userTypeName?: string;
}
export interface ReminderSettingResultDto {
    reminderSettingResultErrorDtos?: ReminderSettingResultErrorDto[];
    reminderSettingDetailDtos?: ReminderSettingDetailDto[];
}
export interface ReminderSettingResultErrorDto {
    firstId?: string;
    secondId?: string;
    error?: string;
}
export interface ScheduledJobSettingDto {
    jobName?: string;
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    lastTimeJobRan?: Date;
    cronDescription?: string;
    nextExecutions?: Date[];
    canIRun?: boolean;
    methodName?: string;
}
export interface ScheduledJobSettingListDto {
    jobName?: string;
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    lastTimeJobRan?: Date;
    canIRun?: boolean;
}
export interface ScheduledJobSettingUpdateDto {
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    canIRun?: boolean;
    lastTimeJobRan?: Date;
    methodName?: string;
}
export interface SiteDownMessageDto {
    message?: string;
    from?: Date;
    to?: Date;
}
export interface ErrorMaintenanceGridOutputDto extends GridOutputBaseDto {
    errors?: ErrorMaintenanceDetailOutputDto[];
}
export interface ErrorMaintenanceDetailOutputDto {
    id?: number;
    severityLevel?: string;
    message?: string;
    exception?: string;
    properties?: JsonDocument;
    raiseDate?: Date;
}
export interface JsonDocument {
    isDisposable?: boolean;
    /** Gets the root element of this JSON document. */
    rootElement?: any;
}
export interface ErrorMaintenanceSearchInputDto extends SearchInputBaseDto {
    severityLevel?: ErrorMaintenanceSeverityLevel;
    fromDate?: Date;
    toDate?: Date;
}
export enum ErrorMaintenanceSeverityLevel {
    All = 0,
    Error = 1,
    Warning = 2,
    Information = 3,
    Debug = 4,
}
export interface ErrorMaintenanceDeleteInputDto {
    ids?: number[];
}
export interface TemplateCategoryBaseDto {
    name?: string;
    description?: string;
}
export interface TemplateCategoryOutputBaseDto extends TemplateCategoryBaseDto {
    id?: string;
}
export interface TemplateCategoryOutputDto extends TemplateCategoryOutputBaseDto {
    canBeDeleted?: boolean;
    uniqueFieldName?: string;
}
export interface TemplateCategoryGridOutputDto extends GridOutputBaseDto {
    templateCategories?: TemplateCategoryOutputBaseDto[];
}
export interface TemplateCategorySearchInputDto extends SearchInputBaseDto {
}
export interface TemplateCategoryGridWithReminderOutputDto extends GridOutputBaseDto {
    templateCategories?: TemplateCategoryOutputWithReminderDto[];
}
export interface TemplateCategoryOutputWithReminderDto extends TemplateCategoryBaseDto {
    id?: string;
    reminderSetting?: ReminderSettingDto;
}
export interface TemplateCategoryReminderSearchInputDto extends SearchInputBaseDto {
    reminderListFilterEnum?: ReminderListFilterEnum;
}
export interface TemplateCategoryAvailableDatesOutputDto {
    fieldId?: number;
    fieldName?: string;
    fieldDescription?: string;
}
export interface TemplateCategoryInputDto extends TemplateCategoryBaseDto {
}
export interface UserOutputDto {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    userTimeZone?: string;
    archived?: boolean;
    allowToLogin?: boolean;
    allowReminders?: boolean;
    entityTemplateId?: string;
    roles?: UserRoleOutputDto[];
    userTypeForUserOutputDtos?: UserTypeForUserOutputDto[];
    fields?: EntityFieldFilledOutputDto[];
    notificationOutputDetailDtos?: NotificationOutputDetailDto[];
}
export interface UserRoleOutputDto {
    rolesEnum?: RolesEnum;
    roleName?: string;
}
export interface UserTypeForUserOutputDto {
    id?: number;
    name?: string;
    canManageUsers?: boolean;
    numberOfUsers?: number;
}
export interface UserOutputForReminderManagerDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    entityTemplateName?: string;
    entityTemplateId?: string;
}
export interface UserGridOutputDto extends GridOutputBaseDto {
    users?: UserOutputDto[];
}
export interface UserSearchInputDto extends SearchInputBaseDto {
    allowLogIn?: boolean;
    archived?: boolean;
    allowReminders?: boolean;
    rolesEnum?: RolesEnum;
    userTypeId?: number;
}
export interface UserGridReminderManagerOutputDto extends GridOutputBaseDto {
    users?: UserOutputReminderManagerDto[];
}
export interface UserOutputReminderManagerDto {
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    entityTemplateName?: string;
}
export interface UserSearchReminderManagerInputDto extends SearchInputBaseDto {
}
export interface UserInputBaseDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userTimeZone?: string;
    archived?: boolean;
    allowToLogin?: boolean;
    allowReminders?: boolean;
}
export interface UserCreateDto extends UserInputBaseDto {
    userName?: string;
    roles?: RolesEnum[];
    sendPasswordEmail?: boolean;
    userTypeIds?: number[];
}
export interface UserUpdateDto extends UserInputBaseDto {
    roles?: RolesEnum[];
    userTypeIds?: number[];
    fields?: EntityFieldFilledInDto[];
}
export interface UserTemplateInputDto {
    templateId?: string;
}
export interface UserSettingBaseDto {
    setting?: string;
}
export interface UserSettingSettingOutputDto extends UserSettingBaseDto {
    settingId?: string;
}
export interface UserSettingSettingInputDto extends UserSettingBaseDto {
}
export interface UserTypeBaseDto {
    name?: string;
    description?: string;
    canManageUsers?: boolean;
    canReceiveAnyReminder?: boolean;
}
export interface UserTypeOutputBaseDto extends UserTypeBaseDto {
    id?: number;
}
export interface UserTypeOutputDto extends UserTypeOutputBaseDto {
    canBeDeleted?: boolean;
}
export interface UserTypeGridOutputDto extends GridOutputBaseDto {
    userTypes?: UserTypeOutputBaseDto[];
}
export interface UserTypeSearchInputDto extends SearchInputBaseDto {
}
export interface UserTypeInputDto extends UserTypeBaseDto {
}
export interface FileParameter {
    data: any;
    fileName: string;
}
export interface FileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}
