import React, { useEffect, useMemo, useState } from "react";
import FormModal from "../components/FormModal";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysModels from "../models";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { useErrorDialog } from "../stores/SystemStore";
import CommonSpinner from "../components/CommonSpinner";

function LinkDialog(props: {
  entityId: string;
  id?: string;
  close: (data?: SysModels.EntityLinkOutputDto) => void;
}) {
  const errDialog = useErrorDialog();
  const [model, setModel] = useState<SysModels.EntityLinkInputDto>({
    url: "",
    urlText: "",
    description: "",
  });
  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    await (props.id
      ? SysServices.http.entityLink.update(props.id, {
          ...model,
        })
      : SysServices.http.entityLink.create({
          ...model,
          entityId: props.entityId,
        })
    )
      .then((data) => {
        toastStore.showToast("Link Saved", "success");
        props.close({
          ...model,
        });
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Link", err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const allowSubmit = useMemo(() => {
    if (commonService.isNullOrWhitespace(model.url || "")) {
      return false;
    }
    return commonService.regexPatterns.url.test(model.url || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.id) {
      setLoading(true);
      SysServices.http.entityLink
        .get(props.id)
        .then((data) => {
          setModel(data);
          commonService.focusInput("textURL", 500);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Link", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      commonService.focusInput("textURL", 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title={`${props.id ? "Edit" : "Add"} Link`}
      size="md"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {
        save();
      }}
      submitButtonLabel={saving ? "Saving..." : "Submit"}
      disableSubmit={!allowSubmit || loading}
      moveBehind={errDialog.show}
    >
      <div>
        {loading && (
          <CommonSpinner overlay={true} message="Loading..."></CommonSpinner>
        )}
        <div style={{ width: "100%" }}>
          <div className="pt-2">
            <div className="mb-2">
              <div className="mb-1">
                <label className="required-label">Link URL</label>
              </div>
              <input
                id="textURL"
                placeholder="URL"
                className="form-control"
                value={model.url || ""}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      url: e.target.value || "",
                    };
                  });
                }}
              ></input>
            </div>
            <div className="mb-2">
              <div className="mb-1">
                <label>Link Text</label>
              </div>
              <input
                placeholder="URL Text"
                className="form-control"
                value={model.urlText || ""}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      urlText: e.target.value || "",
                    };
                  });
                }}
              ></input>
            </div>
            <div className="mb-2">
              <div className="mb-1">
                <label>Link Description</label>
              </div>
              <textarea
                className="form-control"
                placeholder="Description"
                value={model.description}
                rows={3}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default LinkDialog;
