import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import DocEditor from "../components/DocEditor";
import { Tab, Tabs } from "react-bootstrap";
import commonService from "../services/CommonService";
import SwitchButton from "../components/SwitchButton";

function HowTo(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [howToId, setHowToId] = useState(id);
  const [model, setModel] = useState<SysModels.HowToOutputDraftDto>({});

  const current = useFetchHelper(
    async () => SysServices.http.howTo.get(howToId || ""),
    "How To"
  );

  const roles = useFetchHelper(
    () => SysServices.http.genericEnumLookup.getRolesEnums(),
    "Roles"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    if (!Number(model.sequence) || Number(model.sequence) <= 0) {
      toastStore.showToast("Sequence must be greater than '0'", "warning");
      return;
    }

    if (model.displayOnHomePage && !model.roles?.length) {
      toastStore.showToast("Please pick a role where to display", "warning");
      return;
    }

    setSaving(true);
    if (howToId && howToId !== "new") {
      await SysServices.http.howTo
        .update(howToId as any, {
          ...model,
        })
        .then((data) => {
          toastStore.showToast("How To Saved", "success");
          setModel(data);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving How To", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.howTo
        .create({
          ...model,
        })
        .then((data) => {
          toastStore.showToast("How To Saved", "success");
          setModel(data);
          setHowToId(data.id);
          current.setDataAndComplete(data);
          nav(`/how-to-maintenance/${data.id}`);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving How To", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    roles.getData();
    if (howToId && howToId !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/how-to-maintenance");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [confirmPublish, setConfirmPublish] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [tab, setTab] = useState("Draft");

  return (
    <div className="h-100 flex flex-column">
      <ConfirmDialog
        show={showDel}
        title="Delete How To"
        message="Do you really want to delete this How To?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.howTo
              .delete(howToId as any)
              .then((data) => {
                toastStore.showToast("How To Deleted", "success");
                nav("/how-to-maintenance");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting How To", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        show={confirmPublish}
        title="Publish Document"
        message="Do you really want to publish this document?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setPublishing(true);
            SysServices.http.howTo
              .publish(howToId as any)
              .then((data) => {
                toastStore.showToast("Document Published", "success");
                current.getData();
                setTab("Published");
              })
              .catch((err) => {
                toastStore.showError("Failed Publishing Document", err);
              })
              .finally(() => {
                setPublishing(false);
              });
          }
          setConfirmPublish(false);
        }}
      ></ConfirmDialog>

      <h4>{howToId === "new" ? "Add" : "Edit"} How To</h4>

      {current.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}

      {(deleting || publishing) && (
        <CommonSpinner overlay={true}></CommonSpinner>
      )}

      {(current.status === FetchStatus.Complete || howToId === "new") && (
        <div className="flex-1 tab-flex flex flex-column">
          <Tabs
            activeKey={tab}
            id="how-to-tabs"
            className="mt-2"
            onSelect={(e) => {
              e && setTab(e);
            }}
          >
            <Tab eventKey="Draft" title="Draft">
              <div
                className="flex-1 flex flex-column"
                style={{ width: "100%" }}
              >
                <div className="pt-2 row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Title</label>
                      </div>
                      <input
                        className="form-control required"
                        placeholder="Title"
                        autoFocus={true}
                        value={model.titleDraft || ""}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              titleDraft: val.target.value || "",
                            };
                          });
                        }}
                      ></input>
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Sequence</label>
                      </div>
                      <input
                        className="form-control required"
                        placeholder="Sequence"
                        value={model.sequence}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              sequence: val.target.value || ("" as any),
                            };
                          });
                        }}
                        type="number"
                        step="0"
                      ></input>
                    </div>
                    <div className="py-2">
                      <div>
                        <SwitchButton
                          uncheckedLabel="Display On Home Page"
                          checked={model.displayOnHomePage || false}
                          onChange={(val) => {
                            if (val) {
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  displayOnHomePage: val || false,
                                  roles:
                                    prev.roles?.length === 1
                                      ? prev.roles?.filter((r, i) => i === 0)
                                      : [],
                                };
                              });
                            } else {
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  displayOnHomePage: val || false,
                                };
                              });
                            }
                          }}
                        ></SwitchButton>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-8">
                    <div className="mb-4">
                      <div className="mb-1">
                        <label
                          className={
                            model.displayOnHomePage ? "required-label" : ""
                          }
                        >
                          Roles
                        </label>
                      </div>
                      {roles?.data?.map((role) => (
                        <div
                          key={role.value}
                          className="flex flex-row flex-center"
                        >
                          <input
                            type={
                              model.displayOnHomePage ? "radio" : "checkbox"
                            }
                            name="roles-picker"
                            className="me-2"
                            id={`htrole-${role.value}`}
                            checked={model.roles?.includes(role.value as any)}
                            onChange={(e) => {
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  roles: prev.displayOnHomePage
                                    ? [role.value]
                                    : e.target.checked
                                    ? [...(prev.roles || []), role.value as any]
                                    : (prev.roles || []).filter(
                                        (r) => r !== role.value
                                      ),
                                };
                              });
                            }}
                          />
                          <label
                            className="pointer"
                            htmlFor={`htrole-${role.value}`}
                          >
                            {role.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mb-3 flex-1">
                  <div className="mb-1">
                    <label className="required-label">Content</label>
                  </div>
                  <div className="document-body">
                    <div>
                      <DocEditor
                        key={model.id || "new"}
                        content={model.bodyDraft || ""}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              bodyDraft: val,
                            };
                          });
                        }}
                        onSave={() => {
                          save();
                        }}
                        onDialogOpen={(open) => {}}
                      ></DocEditor>
                    </div>
                  </div>
                </div>
                <div className="pt-2">
                  <button
                    className="btn btn-sm btn-primary float-right"
                    type="button"
                    onClick={(e) => {
                      save();
                    }}
                    disabled={saving || deleting}
                  >
                    {saving ? "Saving..." : "Submit"}
                  </button>
                  <button
                    className="btn btn-sm btn-secondary me-2 float-right"
                    type="button"
                    onClick={(e) => {
                      nav("/how-to-maintenance");
                    }}
                    disabled={saving || deleting}
                  >
                    Cancel
                  </button>

                  {howToId !== "new" && (
                    <>
                      <button
                        className="btn btn-sm btn-success me-2 float-right"
                        type="button"
                        onClick={(e) => {
                          setConfirmPublish(true);
                        }}
                        disabled={saving || deleting || publishing}
                      >
                        {publishing ? "Publishing..." : "Publish"}
                      </button>
                      {!!model.publishedOn && (
                        <span className="float-right me-3 mt-1">
                          <small>
                            Published On:{" "}
                            {commonService.toDateString(
                              model.publishedOn,
                              "full"
                            )}
                          </small>
                        </span>
                      )}
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={(e) => {
                          setShowDel(true);
                        }}
                        disabled={saving || deleting}
                      >
                        {deleting ? "Deleting..." : "Delete"}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Tab>
            {howToId !== "new" && !!current.data?.publishedOn && (
              <Tab eventKey="Published" title="Published" className="published">
                <div>
                  <h5 className="mb-1">{current.data?.title}</h5>
                  <div className="mb-4">
                    <small>
                      {commonService.toDateString(
                        current.data.publishedOn,
                        "full"
                      )}
                    </small>
                  </div>
                  <div className="document-body">
                    <div>
                      <DocEditor
                        key="published-editor"
                        content={current.data?.body || ""}
                        onChange={(val) => {}}
                        onSave={() => {}}
                        onDialogOpen={(open) => {}}
                        readonly={true}
                      ></DocEditor>
                    </div>
                  </div>
                </div>
              </Tab>
            )}
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default HowTo;
