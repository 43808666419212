import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import { useCompanySettings, useLastPageFilters } from "../stores/SystemStore";
import commonService from "../services/CommonService";
import SysModels from "../models";
import PickTemplateDialog from "./PickTemplateDialog";
import FullEntityDialog from "./FullEntityDialog";

function FullEntities(props: { viewOnly?: boolean }) {
  const companySettings = useCompanySettings();
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(
    async () =>
      SysServices.http.fullTemplateEntity.list(paging.page, paging.pageSize, {
        search: search.used,
      }),
    companySettings?.entityLabelPlural || "Entities"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready]);

  const [showPickTemp, setShowPickTemp] = useState(false);

  const types = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("WF1FieldType"),
    "Field Types"
  );

  useEffect(() => {
    types.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDialog, setShowDialog] = useState<{
    id?: string;
    templateId?: string;
  }>();

  return (
    <div>
      <div className="mb-3">
        <h4>
          {props.viewOnly
            ? "View Reminders"
            : companySettings?.entityLabelPlural}
        </h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {showPickTemp && (
        <PickTemplateDialog
          fullTemplate={true}
          onClose={(templateId?: string) => {
            setShowPickTemp(false);
            if (templateId) {
              setShowDialog({
                templateId: templateId,
                id: undefined,
              });
            }
          }}
        />
      )}
      {showDialog && (
        <FullEntityDialog
          entityId={showDialog.id}
          templateId={showDialog.templateId}
          close={(changed) => {
            setShowDialog(undefined);
            if (changed) {
              list.getData();
            }
          }}
        />
      )}
      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-1">
              {!props.viewOnly && (
                <button
                  className="btn btn-primary no-wrap"
                  type="button"
                  onClick={(e) => {
                    setShowPickTemp(true);
                  }}
                >
                  Add {companySettings?.entityLabelSingular}
                </button>
              )}
            </div>
            <div className="flex-1"></div>
          </div>

          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <div
                className="row mb-2"
                style={{
                  backgroundColor: "#eee",
                  padding: "12px 0px",
                  paddingBottom: "0",
                  margin: "0",
                  borderRadius: "5px",
                }}
              >
                {!list?.data?.fullTemplateEntityGridItemOutputDtos?.length && (
                  <div className="pb-2 m-2">No Record(s) Found</div>
                )}
                {list?.data?.fullTemplateEntityGridItemOutputDtos?.map(
                  (card) => (
                    <div
                      key={card.id}
                      className="col-sm-12 col-md-4 col-lg-3 pb-3"
                    >
                      <div
                        className="card pointer h-100"
                        onClick={(e) => {
                          setShowDialog({
                            id: card.id,
                            templateId: undefined,
                          });
                          //nav(`/cards/${card.id}`);
                        }}
                      >
                        <div className="card-body flex flex-column">
                          <label className="mb-2">
                            {card.entityTemplateName}
                          </label>
                          <div className="flex-1">
                            <table className="table table-sm table-bordered mb-0">
                              <tbody>
                                {card.fields
                                  ?.sort(
                                    commonService.sortByNumericProperty(
                                      "sequence"
                                    )
                                  )
                                  ?.map((fd) => (
                                    <tr key={fd.sequence}>
                                      <td>
                                        <label>{fd.name}</label>
                                      </td>
                                      <td>
                                        {fd.fieldType ===
                                        SysModels.WF1FieldType.Date ? (
                                          <>
                                            {commonService.toDateString(
                                              fd.value,
                                              "ddd, MMM DD, YYYY"
                                            )}
                                          </>
                                        ) : (
                                          <>{fd.value}</>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                {/* <tr>
                                  <td>Participants</td>
                                  <td>{card.numberOfParticipants}</td>
                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                          <div className="pt-2 flex flex-row flex-center">
                            <div className="flex-1 text-secondary">
                              Participants: {card.numberOfParticipants}
                            </div>
                            <div>
                              <i
                                className="fa fa-external-link pointer text-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  nav(`/cards/${card.id}`);
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel={
                    companySettings?.entityLabelPlural || "Entities"
                  }
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default FullEntities;
