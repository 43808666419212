import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import SysServices from "../services";
import SysModels from "../models";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore, {
  useCompanySettings,
  useLoginStatus,
} from "../stores/SystemStore";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import { Dropdown } from "react-bootstrap";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";

interface IParticipantsModel {
  userFullName?: string;
  participants?: {
    userId?: string;
    access?: SysModels.FullTemplateEntityAccess;
    name?: string;
  }[];
  userId?: string;
}

function ParticipantsTab(props: {
  fullTemplate: boolean;
  isNew: boolean;
  tabMode?: boolean;
  entityId?: string;
  model: IParticipantsModel;
  myAccess?: SysModels.FullTemplateEntityAccess;
  onChange: (data: IParticipantsModel) => void;
}) {
  const [model, setModel] = useState(props.model);
  const [itemUser, setItemUser] = useState([] as any[]);
  const [isLoadingUsers, setIsUserLoading] = useState(false);
  const refUser = React.createRef<any>();
  const handleSearchUsers = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setItemUser([]);
      setIsUserLoading(false);
      return;
    }
    setIsUserLoading(true);
    await SysServices.http.user
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setItemUser(options);
        setIsUserLoading(false);
      })
      .catch((err) => {
        setItemUser([]);
        setIsUserLoading(false);
      });
  };

  const accessTypes = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("FullTemplateEntityAccess"),
    "Access Types"
  );

  const statuses = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("ParticipantStatusEnum"),
    "Statuses"
  );

  useEffect(() => {
    if (props.fullTemplate) {
      accessTypes.getData();
      statuses.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fullTemplate]);

  useEffect(() => {
    props.onChange(model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const companySettings = useCompanySettings();
  const loginStatus = useLoginStatus();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const [status, setStatus] = useState(SysModels.ParticipantStatusEnum.Active);
  const list = useFetchHelper(
    async () =>
      SysServices.http.fullTemplateEntity.listParticipants(
        paging.page,
        paging.pageSize,
        {
          search: search.used,
          participantStatus: status,
          entityId: props.entityId || "",
        }
      ),
    "Participants"
  );

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        props.entityId && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, status]);

  useEffect(() => {
    if (props.entityId) {
      setModel((prev) => {
        return {
          ...prev,
          participants: [
            ...(list.data?.fullTemplateParticipantGridItemOutputDtos || []).map(
              (x) => {
                return {
                  userId: x.userId,
                  name: `${x.firstName} ${x.lastName}`,
                  access: x.access,
                };
              }
            ),
          ],
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.data]);

  const [saving, setSaving] = useState<string[]>([]);
  const updateParticipants = async (
    userId: string,
    access: SysModels.FullTemplateEntityAccess
  ) => {
    if (props.entityId) {
      setSaving((p) => {
        return [...p, userId];
      });
      await SysServices.http.fullTemplateEntity
        .updateParticipants(props.entityId, {
          participants: [
            {
              userId: userId,
              access: access,
            },
          ],
        })
        .then((data) => {})
        .catch((err) => {
          toastStore.showError("Failed Saving Participant", err);
        })
        .finally(() => {
          setSaving((p) => {
            return p.filter((u) => u !== userId);
          });
        });
    }
  };

  return (
    <div>
      <div className="flex flex-wrap gap-10 mb-3">
        {props.tabMode && (
          <>
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search Existing..."
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  disabled={list.status === FetchStatus.InProgress}
                  className="no-wrap"
                >
                  <span className="me-2">
                    {statuses.data?.find((s) => s.value === status)?.label}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  {statuses.data?.map((opt) => (
                    <Dropdown.Item
                      key={opt.value}
                      active={status === opt.value}
                      onClick={(e) => {
                        if (opt.value) {
                          pageChange(1, paging.pageSize);
                          setStatus(opt.value);
                        }
                      }}
                    >
                      {opt.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
        <div className="flex-1">
          <div style={props.fullTemplate ? { maxWidth: "300px" } : {}}>
            {!!(props.isNew || props.tabMode) && (
              <AsyncTypeahead
                id="typeahead-search-user"
                labelKey="name"
                renderMenuItemChildren={(option, props, index) => (
                  <TyeaheadItem {...itemUser[index]}></TyeaheadItem>
                )}
                onSearch={handleSearchUsers}
                onChange={(data) => {
                  if (data.length > 0) {
                    const item = data[0] as any;
                    if (props.fullTemplate) {
                      if (
                        item &&
                        model.participants?.find((p) => p.userId === item.id)
                      ) {
                        toastStore.showToast(
                          `'${item.name}' is already added.`,
                          "warning"
                        );
                      } else {
                        setModel((prev) => {
                          return {
                            ...prev,
                            participants: [
                              ...(prev.participants || []),
                              {
                                userId: item ? item.id : undefined,
                                name: item ? item.name : undefined,
                                access:
                                  SysModels.FullTemplateEntityAccess
                                    .Contributor,
                              },
                            ],
                          };
                        });

                        if (props.fullTemplate) {
                          updateParticipants(
                            item.id,
                            SysModels.FullTemplateEntityAccess.Contributor
                          );
                        }
                      }
                    } else {
                      setModel((prev) => {
                        return {
                          ...prev,
                          userId: item ? item.id : undefined,
                          userFullName: item ? item.name : undefined,
                        };
                      });
                    }
                    (refUser.current as any)?.clear();
                  }
                }}
                searchText={"Searching..."}
                isLoading={isLoadingUsers}
                options={itemUser}
                placeholder={`Search for New ${
                  companySettings?.userLabelSingular || "User"
                }`}
                minLength={1}
                delay={500}
                useCache={false}
                filterBy={() => true}
                ref={refUser}
                autoFocus={!props.fullTemplate}
              />
            )}
          </div>
        </div>
      </div>

      <div>
        {props.fullTemplate ? (
          <>
            {list.status === FetchStatus.InProgress && (
              <CommonSpinner message="Loading..."></CommonSpinner>
            )}
            {!!(props.isNew || props.tabMode) &&
              list.status !== FetchStatus.InProgress && (
                <table className="table table-bordered mt-2">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th
                        style={{
                          width: "150px",
                        }}
                      >
                        Access
                      </th>
                      <th
                        style={{
                          width: "60px",
                        }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!model?.participants?.length && (
                      <tr>
                        <td colSpan={3}>No Record(s) Found</td>
                      </tr>
                    )}
                    {model?.participants?.map((p) => (
                      <tr key={p.userId}>
                        <td className="pt-2 px-2">{p.name}</td>
                        <td>
                          {props.myAccess !==
                            SysModels.FullTemplateEntityAccess.Admin ||
                          p.userId === loginStatus.userId ? (
                            <span className="mx-1">
                              {
                                accessTypes.data?.find(
                                  (a) => a.value === p.access
                                )?.label
                              }
                            </span>
                          ) : (
                            <>
                              <select
                                className="form-control"
                                style={{
                                  border: "none",
                                  padding: "0",
                                }}
                                value={p.access}
                                onChange={(e) => {
                                  const val = Number(e.target.value);
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      participants: (
                                        prev.participants || []
                                      ).map((px) => {
                                        if (px.userId !== p.userId) {
                                          return px;
                                        }
                                        return {
                                          ...px,
                                          access: val,
                                        };
                                      }),
                                    };
                                  });
                                  if (props.fullTemplate && p.userId) {
                                    updateParticipants(p.userId, val);
                                  }
                                }}
                              >
                                {accessTypes.data?.map((at) => (
                                  <option key={at.value} value={at.value}>
                                    {at.label}
                                  </option>
                                ))}
                              </select>
                            </>
                          )}
                        </td>
                        <td className="text-center">
                          {props.tabMode ? (
                            <>
                              {!!(p.userId && saving.includes(p.userId)) ? (
                                <span className="mt-1">
                                  <i className="fa fa-spin fa-spinner text-primary"></i>
                                </span>
                              ) : (
                                <>
                                  {p.access ===
                                    SysModels.FullTemplateEntityAccess
                                      .Admin && (
                                    <i className="fa fa-user mt-1"></i>
                                  )}
                                  {p.access ===
                                    SysModels.FullTemplateEntityAccess
                                      .Contributor && (
                                    <i className="fa fa-edit mt-1"></i>
                                  )}
                                  {p.access ===
                                    SysModels.FullTemplateEntityAccess
                                      .Reader && (
                                    <i className="fa fa-eye mt-1"></i>
                                  )}
                                  {p.access ===
                                    SysModels.FullTemplateEntityAccess
                                      .NoAccess && (
                                    <i className="fa fa-eye-slash mt-1"></i>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-sm"
                              title="Delete"
                              onClick={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    participants: (
                                      prev.participants || []
                                    ).filter((px) => p.userId !== px.userId),
                                  };
                                });
                              }}
                            >
                              <i className="fa fa-trash text-danger"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
          </>
        ) : (
          <>
            {model.userId ? (
              <div
                className={`alert alert-sm alert-${
                  !props.isNew ? "secondary" : "primary"
                } mt-2 p-2 px-3`}
              >
                {model.userFullName}
              </div>
            ) : (
              <div
                className={`alert alert-sm alert-${
                  !props.isNew ? "secondary" : "primary"
                } mt-2 p-2 px-3`}
              >
                <i>No User</i>
              </div>
            )}
          </>
        )}
      </div>

      {props.tabMode && (
        <div className="hide-on-print">
          <Pagination
            length={list.data?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
            showingOfWhatLabel={companySettings?.userLabelPlural || "Users"}
            sizes={[10, 15, 25, 50, 100]}
          ></Pagination>
        </div>
      )}
    </div>
  );
}

export default ParticipantsTab;
