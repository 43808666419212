import AppAdminDashboard from "../dashboards/AppAdminDashboard";
import SaaSAdminDashboard from "../dashboards/SaaSAdminDashboard";
import AllActivityLogs from "../pages/AllActivityLogs";
import BlowUpTest from "../pages/BlowupTest";
import Companies from "../pages/Companies";
import Company from "../pages/Company";
import ConfigSetting from "../pages/ConfigSetting";
import ConfigSettings from "../pages/ConfigSettings";
import Dashboard from "../pages/Dashboard";
import Entities from "../pages/Entities";
import Entity from "../pages/Entity";
import Field from "../pages/Field";
import Fields from "../pages/Fields";
import Template from "../pages/Template";
import Templates from "../pages/Templates";
import GlobalSiteMessage from "../pages/GlobalSiteMessage";
import HowTo from "../pages/HowTo";
import HowToList from "../pages/HowToList";
import ReleaseNote from "../pages/ReleaseNote";
import ReleaseNotes from "../pages/ReleaseNotes";
import ScheduledJobSetting from "../pages/ScheduledJobSetting";
import ScheduledJobSettings from "../pages/ScheduledJobSettings";
import SiteDownMessage from "../pages/SiteDownMessage";
import SystemErrors from "../pages/SystemErrors";
import Categories from "../pages/Categories";
import Category from "../pages/Category";
import CategoryReminders from "../pages/CategoryReminders";
import Reminders from "../pages/Reminders";
import User from "../pages/User";
import Users from "../pages/Users";
import UserTemplate from "../pages/UserTemplate";
import UserTemplates from "../pages/UserTemplates";
import UserType from "../pages/UserType";
import UserTypes from "../pages/UserTypes";
import systemStore, { USER_ROLES } from "../stores/SystemStore";
import FullEntities from "../pages/FullEntities";

export interface IMenuAction {
  label: string;
  icon?: string;
  url: string;
  roles: USER_ROLES[];
  hidden?: boolean;
  component?: any;
  dashboard?: boolean;
  otherLabel?: () => string;
  sortOrder?: number;
  canAccess?: () => boolean;
}

class MenuActions {
  get actions() {
    return this._actions.filter((a) => !a.canAccess || a.canAccess());
  }

  getActions() {
    return this._actions; //.filter((a) => !a.canAccess || a.canAccess());
  }

  private _actions: IMenuAction[] = [
    //ROLES
    {
      label: "App Admin",
      url: "/appadmin-dashboard",
      roles: ["AppAdmin"],
      component: AppAdminDashboard,
      hidden: true,
      dashboard: true,
      sortOrder: 1,
    },
    {
      label: "SaaS Admin",
      url: "/saasadmin-dashboard",
      roles: ["SaaSAdmin"],
      component: SaaSAdminDashboard,
      hidden: true,
      dashboard: true,
      sortOrder: 1,
    },
    {
      label: "Admin",
      url: "/admin-dashboard",
      roles: ["Admin"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
      sortOrder: 1,
    },
    {
      label: "Reminder Manager",
      url: "/remindermanager-dashboard",
      roles: ["ReminderManager"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
      sortOrder: 1,
    },
    {
      label: "Template Manager",
      url: "/templatemanager-dashboard",
      roles: ["TemplateManager"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
      sortOrder: 1,
    },
    {
      label: "My Dashboard",
      url: "/user-dashboard",
      roles: ["User"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
      otherLabel: () => "My Dashboard",
      sortOrder: 0,
    },
    {
      label: "User Manager",
      url: "/usermanager-dashboard",
      roles: ["UserManager"],
      component: Dashboard,
      hidden: true,
      dashboard: true,
      sortOrder: 1,
      otherLabel: () =>
        (systemStore.companySettings?.userLabelSingular || "User") + " Manager",
    },
    //Companies
    {
      label: "Companies",
      url: "/companies",
      icon: "fa-building",
      roles: ["SaaSAdmin"],
      component: Companies,
    },
    {
      label: "Company",
      url: "/companies/:id",
      icon: "fa-building",
      roles: ["SaaSAdmin"],
      component: Company,
      hidden: true,
    },
    {
      label: "Company Info",
      url: "/company-info",
      icon: "fa-building",
      roles: ["Admin"],
      component: () => <Company admin={true} />,
    },
    //Config Settings
    {
      label: "Config Settings",
      url: "/config-settings",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ConfigSettings,
    },
    {
      label: "Config Setting",
      url: "/config-settings/:area/:section?",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ConfigSetting,
      hidden: true,
    },
    //Entities
    {
      label: "Entities",
      url: "/reminder-cards",
      icon: "fa-file-text-o",
      roles: ["ReminderManager", "TemplateManager"],
      component: Entities,
      otherLabel: () =>
        systemStore.companySettings?.entityLabelPlural
          ? `Reminder ${systemStore.companySettings?.entityLabelPlural}`
          : "Reminder Entities",
    },
    {
      label: "Entity",
      url: "/reminder-cards/:id",
      icon: "fa-file-text-o",
      roles: ["ReminderManager", "TemplateManager"],
      component: Entity,
      hidden: true,
    },
    {
      label: "Entity",
      url: "/reminder-cards/new/:template",
      icon: "fa-file-text-o",
      roles: ["ReminderManager", "TemplateManager"],
      component: Entity,
      hidden: true,
    },
    //Entities (USER)
    {
      label: "View Reminders",
      url: "/view-reminders",
      icon: "fa-file-text-o",
      roles: ["User"],
      component: () => <Entities viewOnly={true} />,
      otherLabel: () =>
        systemStore.companySettings?.entityLabelPlural
          ? `Reminder ${systemStore.companySettings?.entityLabelPlural}`
          : "Reminder Entities",
    },
    {
      label: "View Reminder",
      url: "/view-reminders/:id",
      icon: "fa-file-text-o",
      roles: ["User"],
      component: () => <Entity viewOnly={true} />,
      hidden: true,
    },
    {
      label: "Full Cards",
      url: "/cards",
      icon: "fa-file-text",
      roles: ["User"],
      component: FullEntities,
      otherLabel: () =>
        systemStore.companySettings?.entityLabelPlural
          ? `${systemStore.companySettings?.entityLabelPlural}`
          : "Entities",
      canAccess: () => {
        return systemStore.companySettings?.allowFullTemplates || false;
      },
    },
    {
      label: "Full Card",
      url: "/cards/:id",
      icon: "fa-file-text",
      roles: ["User"],
      component: () => <Entity fullTemplate={true} />,
      hidden: true,
      canAccess: () => {
        return systemStore.companySettings?.allowFullTemplates || false;
      },
    },
    {
      label: "Full Card",
      url: "/cards/new/:template",
      icon: "fa-file-text",
      roles: ["User"],
      component: () => <Entity fullTemplate={true} />,
      hidden: true,
      canAccess: () => {
        return systemStore.companySettings?.allowFullTemplates || false;
      },
    },
    //Entity Fields
    {
      label: "Fields",
      url: "/fields",
      icon: "fa-edit",
      roles: ["TemplateManager"],
      component: Fields,
    },
    {
      label: "Field",
      url: "/fields/:id",
      icon: "fa-edit",
      roles: ["TemplateManager"],
      component: Field,
      hidden: true,
    },
    //Templates
    {
      label: "Templates",
      url: "/templates",
      icon: "fa-file-o",
      roles: ["TemplateManager"],
      component: Templates,
    },
    {
      label: "Templates",
      url: "/templates/:id",
      icon: "fa-file-o",
      roles: ["TemplateManager"],
      component: Template,
      hidden: true,
    },
    //Categories
    {
      label: "Categories",
      url: "/categories",
      icon: "fa-files-o",
      roles: ["TemplateManager"],
      component: Categories,
    },
    {
      label: "Categories",
      url: "/categories/:id",
      icon: "fa-files-o",
      roles: ["TemplateManager"],
      component: Category,
      hidden: true,
    },
    //Template Reminder
    {
      label: "Templates",
      url: "/template-reminders",
      icon: "fa-file-o",
      roles: ["ReminderManager"],
      component: Reminders,
    },
    {
      label: "Templates",
      url: "/template-reminders/:id",
      icon: "fa-file-o",
      roles: ["ReminderManager"],
      component: Template,
      hidden: true,
    },
    //Category Reminder
    {
      label: "Categories",
      url: "/category-reminders",
      icon: "fa-files-o",
      roles: ["ReminderManager"],
      component: CategoryReminders,
    },
    {
      label: "Categories",
      url: "/category-reminders/:id",
      icon: "fa-files-o",
      roles: ["ReminderManager"],
      component: Category,
      hidden: true,
    },
    //Users
    {
      label: "Users",
      url: "/users",
      icon: "fa-user",
      roles: ["AppAdmin", "SaaSAdmin", "Admin", "UserManager"],
      component: Users,
      otherLabel: () => systemStore.companySettings?.userLabelPlural || "Users",
    },
    {
      label: "User",
      url: "/users/:id",
      icon: "fa-user",
      roles: ["AppAdmin", "SaaSAdmin", "Admin", "UserManager"],
      component: User,
      hidden: true,
    },
    //User Templates
    {
      label: "User Templates",
      url: "/user-templates",
      icon: "fa-user",
      roles: ["Admin", "UserManager"],
      component: UserTemplates,
      otherLabel: () =>
        (systemStore.companySettings?.userLabelSingular || "User") +
        " Templates",
    },
    {
      label: "User Template",
      url: "/user-templates/:username",
      icon: "fa-user",
      roles: ["Admin", "UserManager"],
      component: UserTemplate,
      hidden: true,
    },
    //Groups
    {
      label: "Groups",
      url: "/user-types",
      icon: "fa-users",
      roles: ["Admin", "UserManager"],
      component: UserTypes,
    },
    {
      label: "Group",
      url: "/user-types/:id",
      icon: "fa-users",
      roles: ["Admin", "UserManager"],
      component: UserType,
      hidden: true,
    },
    //System Logs
    {
      label: "System Logs",
      url: "/system-logs",
      icon: "fa-server",
      roles: ["AppAdmin"],
      component: SystemErrors,
    },
    //Release Notes
    {
      label: "Release Notes",
      url: "/release-notes-maint",
      icon: "fa-sticky-note-o",
      roles: ["AppAdmin"],
      component: () => <ReleaseNotes forAppAdmin={true} />,
    },
    {
      label: "Release Notes",
      url: "/release-notes-maint/:id",
      icon: "fa-sticky-note-o",
      roles: ["AppAdmin"],
      component: ReleaseNote,
      hidden: true,
    },
    {
      label: "Release Notes",
      url: "/release-notes",
      icon: "fa-sticky-note-o",
      roles: [
        "SaaSAdmin",
        "Admin",
        "ReminderManager",
        "TemplateManager",
        "User",
        "UserManager",
      ],
      component: ReleaseNotes,
      sortOrder: 99,
    },
    //Global Site Message
    {
      label: "Global Site Message",
      url: "/global-site-message",
      icon: "fa-bullhorn",
      roles: ["AppAdmin"],
      component: GlobalSiteMessage,
    },
    //Site Down Message
    {
      label: "Site Down Message",
      url: "/site-down-message",
      icon: "fa-warning",
      roles: ["AppAdmin"],
      component: SiteDownMessage,
    },
    //Scheduled Job Settings
    {
      label: "Scheduled Job Settings",
      url: "/scheduled-job-settings",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ScheduledJobSettings,
    },
    {
      label: "Scheduled Job Setting",
      url: "/scheduled-job-settings/:jobname",
      icon: "fa-cog",
      roles: ["AppAdmin"],
      component: ScheduledJobSetting,
      hidden: true,
    },
    //Activity Logs
    {
      label: "Activity Logs",
      url: "/activity-logs",
      icon: "fa-history",
      roles: ["AppAdmin"],
      component: AllActivityLogs,
    },
    //How To
    {
      label: "How To",
      url: "/how-to-maintenance",
      icon: "fa-question-circle",
      roles: ["AppAdmin"],
      component: () => <HowToList forAppAdmin={true} />,
    },
    {
      label: "How To",
      url: "/how-to",
      icon: "fa-question-circle",
      roles: [
        "Admin",
        "SaaSAdmin",
        "ReminderManager",
        "TemplateManager",
        "User",
        "UserManager",
      ],
      component: HowToList,
      sortOrder: 9,
    },
    {
      label: "How To",
      url: "/how-to-maintenance/:id",
      icon: "fa-question-circle",
      roles: ["AppAdmin"],
      component: HowTo,
      hidden: true,
      sortOrder: 9,
    },
    {
      label: "Error Test",
      url: "/error-test",
      icon: "fa-flask",
      roles: ["AppAdmin"],
      component: BlowUpTest,
    },
  ];
}

const menuActions = new MenuActions();
export default menuActions;
