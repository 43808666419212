import SysModels from "../../models";
import fetcher from "../Fetcher";

class LogService {
  logs = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/Logs/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsUsers = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/LogsUsers/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsEntityField = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/LogsEntityField/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsEntityTemplate = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/LogsEntityTemplate/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsTemplateCategory = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/LogsTemplateCategory/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsGroups = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/LogsGroups/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsEntity = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/LogsEntity/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsCompany = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchDto
  ) => {
    const url = `/Log/LogsCompany/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  logsForCompanyAdim = async (
    page: number,
    pageSize: number,
    model: SysModels.LogSearchCompanyWideDto
  ) => {
    const url = `/Log/LogsForCompanyAdim/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, model);
  };

  companyInfo = async (page: number, pageSize: number) => {
    const url = `/Log/CompanyInfo/${page}/${pageSize}`;
    return fetcher.post<SysModels.LogGridDto>(url, {});
  };
}

const logService = new LogService();
export default logService;
