import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SysModels from "../models";
import DateTimePicker from "../components/DateTimePicker";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import ConfirmDialog from "../components/ConfirmDialog";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import ActivityLogs from "../components/ActivityLogs";
import CommonSpinner from "../components/CommonSpinner";
import { useCompanySettings, useLoadedTab } from "../stores/SystemStore";
import { Tab, Tabs } from "react-bootstrap";
import NotificationsTab from "../components/NotificationsTab";
import ParticipantsTab from "../tabs/ParticipantsTab";
import CommentsTab from "../tabs/CommentsTab";
import LinksTab from "../tabs/LinksTab";
import FilesTab from "../tabs/FilesTab";

function Entity(props: {
  viewOnly?: boolean;
  fullTemplate?: boolean;
  idForDialog?: string;
  templateIdForDialog?: string;
  closeDialog?: (changed?: boolean) => void;
  modalOpened?: (open: boolean) => void;
}) {
  const nav = useNavigate();
  const params = useParams(); //{ id, template };
  const [id] = useState(props.idForDialog || params.id);
  const [template] = useState(props.templateIdForDialog || params.template);

  const companySettings = useCompanySettings();

  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState<
    SysModels.EntityInputDto & {
      userFullName?: string;
      participants?: SysModels.FullTemplateEntityParticipantDto[];
    }
  >({
    fields: [],
  });

  const entityTemplate = useFetchHelper(
    () =>
      SysServices.http.entityTemplate.getEntityTemplateForEntity(
        template || ""
      ),
    "Template"
  );

  const accessTypes = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("FullTemplateEntityAccess"),
    "Access Types"
  );

  const entity = useFetchHelper(() => {
    let prom: Promise<
      SysModels.FullTemplateEntityOutputDto & SysModels.EntityOutputDto
    >;
    if (props.fullTemplate) {
      prom = SysServices.http.fullTemplateEntity.get(id || "");
    } else {
      prom = (
        props.viewOnly
          ? SysServices.http.entity.get
          : SysServices.http.entity.getEntityReminderManager
      )(id || "");
    }
    return prom;
  }, companySettings?.entityLabelSingular || "Entity");

  useEffect(() => {
    if (template) {
      entityTemplate.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (props.fullTemplate) {
      accessTypes.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fullTemplate]);

  useEffect(() => {
    if (!entity.data?.id && id && id !== "new") {
      entity.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (entity.status === FetchStatus.Complete && entity.data) {
      setModel({
        ...entity.data,
        userFullName: `${entity.data.lastName || ""}, ${
          entity.data.firstName || ""
        }`,
      });

      entityTemplate.setData({
        ...entity.data,
        fields: entity.data.fields?.map((f) => {
          return {
            ...f,
            id: f.entityFieldId,
            sequence: f.sequence,
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.status]);

  useEffect(() => {
    if (props.fullTemplate && entityTemplate.data?.fields?.length) {
      const fld = entityTemplate.data?.fields?.sort(
        commonService.sortByNumericProperty("sequence")
      )[0];
      if (fld?.id) {
        setTimeout(() => {
          document
            .getElementById(`field${fld.id}`)
            ?.querySelector("input")
            ?.focus();
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityTemplate.data]);

  const setFieldValue = (fieldId: any, value: any) => {
    setModel((prev) => {
      return {
        ...prev,
        fields: (prev.fields || []).find((f) => f.entityFieldId === fieldId)
          ? [
              ...(prev.fields || []).map((f) => {
                if (f.entityFieldId === fieldId) {
                  return {
                    ...f,
                    value: value,
                  };
                }
                return f;
              }),
            ]
          : [
              ...(prev.fields || []),
              {
                entityFieldId: fieldId,
                value: value,
              },
            ],
      };
    });
  };

  const getFiedlValues = (fieldId: any) => {
    return (model.fields || []).find((f) => f.entityFieldId === fieldId)?.value;
  };

  const getFieldValueList = () => {
    if (entityTemplate.data?.fields?.length) {
      return entityTemplate.data.fields.map((f) => {
        const val = getFiedlValues(f.id);
        return {
          entityFieldId: f.id,
          value: val === undefined ? (null as any) : val,
        };
      });
    }
    return model.fields?.map((f) => {
      const val = getFiedlValues(f.entityFieldId);
      return {
        entityFieldId: f.entityFieldId,
        value: val === undefined ? (null as any) : val,
      };
    });
  };

  const validateForm = () => {
    const err = commonService.getFormErrors("entityForm");
    if (err.length) {
      toastStore.showToast(err[0], "warning");
    }
    return err.length === 0;
  };

  const save = () => {
    if (!validateForm()) return;

    setSaving(true);
    if (entity.data?.id) {
      (props.fullTemplate
        ? SysServices.http.fullTemplateEntity.update(entity.data?.id, {
            ...model,
            fields: getFieldValueList(),
          })
        : SysServices.http.entity.update(entity.data?.id, {
            ...model,
            fields: getFieldValueList(),
          })
      )
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.entityLabelSingular} Saved.`,
            "success"
          );
          entity.setDataAndComplete(data);
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.entityLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      (props.fullTemplate
        ? SysServices.http.fullTemplateEntity.create({
            ...model,
            entityTemplateId: entityTemplate.data?.id,
            fields: getFieldValueList(),
          })
        : SysServices.http.entity.create({
            ...model,
            entityTemplateId: entityTemplate.data?.id,
            fields: getFieldValueList(),
          })
      )
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.entityLabelSingular} Saved.`,
            "success"
          );
          if (data.id) {
            entity.setDataAndComplete(data);
            if (props.fullTemplate) {
              if (props.idForDialog || props.templateIdForDialog) {
                props.closeDialog?.(true);
              } else {
                nav(`/cards/${data.id}`, { replace: true });
              }
            } else {
              nav(`/reminder-cards/${data.id}`, { replace: true });
            }
          }
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.entityLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (props.modalOpened) {
      props.modalOpened(showDel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDel]);

  const tabs = useLoadedTab(["Detail"]);

  return (
    <div className="tabbed-page">
      {!props.idForDialog && !props.templateIdForDialog && (
        <h4>
          {entity.data?.id ? (props.viewOnly ? "View" : "Edit") : "Add"}{" "}
          {companySettings?.entityLabelSingular}:{" "}
          {entityTemplate.data?.name || entity.data?.entityTemplateName}
        </h4>
      )}

      <ConfirmDialog
        show={showDel}
        title={`Delete ${companySettings?.entityLabelSingular}`}
        message={`Do you really want to delete this ${companySettings?.entityLabelSingular}?`}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            (props.fullTemplate
              ? SysServices.http.fullTemplateEntity.delete(id || "")
              : SysServices.http.entity.delete(id || "")
            )
              .then((data) => {
                toastStore.showToast(
                  `${companySettings?.entityLabelSingular} Deleted`,
                  "success"
                );

                if (props.fullTemplate) {
                  nav("/cards");
                  props.closeDialog?.(true);
                } else {
                  nav("/reminder-cards");
                }
              })
              .catch((err) => {
                toastStore.showError(
                  `Failed Deleting ${companySettings?.entityLabelSingular}`,
                  err
                );
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      {deleting && (
        <CommonSpinner message="Deleting..." overlay={true}></CommonSpinner>
      )}
      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          <div id="entityForm">
            {entity.status === FetchStatus.InProgress && (
              <CommonSpinner message="Loading..."></CommonSpinner>
            )}
            <div
              className={`row ${
                entity.status === FetchStatus.InProgress ? "display-none" : ""
              }`}
            >
              <div
                className={
                  props.fullTemplate ? `col-sm-12 col-md-6 col-lg-8` : "row"
                }
                style={{
                  order: props.fullTemplate ? 1 : 0,
                }}
              >
                <div
                  className={`pt-2 mb-2 ${
                    props.fullTemplate ? "" : "col-sm-12 col-md-6 col-lg-4 pe-0"
                  }`}
                >
                  <div className="mb-1">
                    <label>
                      {props.fullTemplate
                        ? entity.data?.id
                          ? ""
                          : "Participants"
                        : "User"}
                    </label>
                  </div>
                  <div>
                    {(!id || (!props.fullTemplate && id)) && (
                      <ParticipantsTab
                        fullTemplate={props.fullTemplate || false}
                        isNew={!id}
                        model={{
                          userFullName: model.userFullName,
                          userId: model.userId,
                          participants: model.participants,
                        }}
                        myAccess={SysModels.FullTemplateEntityAccess.Admin}
                        onChange={(data) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              ...data,
                            };
                          });
                        }}
                      ></ParticipantsTab>
                    )}
                    {/* {!entity.data?.id && (
                      <>
                        <AsyncTypeahead
                          id="typeahead-search-user"
                          labelKey="name"
                          renderMenuItemChildren={(option, props, index) => (
                            <TyeaheadItem {...itemUser[index]}></TyeaheadItem>
                          )}
                          onSearch={handleSearchUsers}
                          onChange={(data) => {
                            if (data.length > 0) {
                              const item = data[0] as any;

                              if (props.fullTemplate) {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    participants: [
                                      ...(prev.participants || []),
                                      {
                                        userId: item ? item.id : undefined,
                                        name: item ? item.name : undefined,
                                        access:
                                          SysModels.FullTemplateEntityAccess
                                            .Contributor,
                                      },
                                    ],
                                  };
                                });
                              } else {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    userId: item ? item.id : undefined,
                                    userFullName: item ? item.name : undefined,
                                  };
                                });
                              }

                              (refUser.current as any)?.clear();
                            }
                          }}
                          searchText={"Searching..."}
                          isLoading={isLoadingUsers}
                          options={itemUser}
                          placeholder="Search User"
                          minLength={1}
                          delay={500}
                          useCache={false}
                          filterBy={() => true}
                          ref={refUser}
                          autoFocus={true}
                        />
                      </>
                    )}
                    {props.fullTemplate ? (
                      <>
                        <table className="table table-sm table-bordered mt-2">
                          <tbody>
                            {model?.participants?.map((p) => (
                              <tr key={p.userId}>
                                <td className="pt-2 px-2">{p.name}</td>
                                <td
                                  style={{
                                    width: "140px",
                                  }}
                                >
                                  <select
                                    className="form-control"
                                    value={p.access}
                                    onChange={(e) => {
                                      const val = Number(e.target.value);
                                      setModel((prev) => {
                                        return {
                                          ...prev,
                                          participants: (
                                            prev.participants || []
                                          ).map((px) => {
                                            if (px.userId !== p.userId) {
                                              return px;
                                            }
                                            return {
                                              ...px,
                                              access: val,
                                            };
                                          }),
                                        };
                                      });
                                    }}
                                  >
                                    {accessTypes.data?.map((at) => (
                                      <option key={at.value} value={at.value}>
                                        {at.label}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td
                                  className="pt-2 text-center"
                                  style={{
                                    width: "60px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-danger"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {model.userId ? (
                          <div
                            className={`alert alert-sm alert-${
                              entity.data?.id ? "secondary" : "primary"
                            } mt-2 p-2 px-3`}
                          >
                            {model.userFullName}
                          </div>
                        ) : (
                          <div
                            className={`alert alert-sm alert-${
                              entity.data?.id ? "secondary" : "primary"
                            } mt-2 p-2 px-3`}
                          >
                            <i>No User</i>
                          </div>
                        )}
                      </>
                    )} */}
                  </div>
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-6 ${
                  props.idForDialog ? "" : "col-lg-4"
                }`}
                style={{
                  order: 0,
                }}
              >
                <div>
                  {props.idForDialog && (
                    <h5>
                      {entityTemplate.data?.name ||
                        entity.data?.entityTemplateName}
                    </h5>
                  )}
                </div>

                <div className="pt-2">
                  {entityTemplate.data?.fields
                    ?.sort(commonService.sortByNumericProperty("sequence"))
                    ?.map((field) => (
                      <div
                        className="mb-2"
                        key={field.id}
                        id={`field${field.id}`}
                      >
                        <div className="mb-1">
                          <label
                            className={
                              field.isFieldRequired ? "required-label" : ""
                            }
                          >
                            {field.name}
                          </label>
                        </div>
                        <div className="input-with-help-icon">
                          {!commonService.isNullOrEmpty(
                            field.description || ""
                          ) && (
                            <i
                              className="fa fa-info-circle text-primary pointer"
                              title={field.description}
                            ></i>
                          )}
                          {field.fieldType === SysModels.WF1FieldType.Date && (
                            <>
                              <div>
                                <DateTimePicker
                                  dateOnly={true}
                                  data={getFiedlValues(field.id)}
                                  onChange={(data) => {
                                    setFieldValue(field.id, data || "");
                                  }}
                                  disabled={props.viewOnly}
                                />
                              </div>
                              {field.isFieldRequired && (
                                <HiddenInputRequiredRef
                                  value={getFiedlValues(field.id)}
                                  placeholder={field.name || ""}
                                  onFocus={() => {
                                    document
                                      .getElementById(`field${field.id}`)
                                      ?.querySelector("input")
                                      ?.focus();
                                  }}
                                />
                              )}
                            </>
                          )}
                          {field.fieldType === SysModels.WF1FieldType.Text && (
                            <input
                              type="text"
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly}
                            />
                          )}
                          {field.fieldType ===
                            SysModels.WF1FieldType.TextArea && (
                            <textarea
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              rows={3}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly}
                            />
                          )}
                          {field.fieldType ===
                            SysModels.WF1FieldType.Number && (
                            <input
                              type="number"
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="pt-2">
                  {!props.viewOnly && (
                    <>
                      {entity.data?.id && (
                        <button
                          className={`btn btn-sm btn-danger ${
                            props.viewOnly ? "" : "me-2"
                          }`}
                          type="button"
                          onClick={(e) => {
                            setShowDel(true);
                          }}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        className="btn btn-sm btn-primary float-right"
                        type="button"
                        onClick={(e) => {
                          save();
                        }}
                        disabled={saving}
                      >
                        {saving ? "Saving..." : "Submit"}
                      </button>
                    </>
                  )}
                  <button
                    className={`btn btn-sm btn-secondary float-right ${
                      props.viewOnly ? "" : "me-2"
                    }`}
                    type="button"
                    onClick={(e) => {
                      if (props.idForDialog || props.templateIdForDialog) {
                        props.closeDialog?.();
                        return;
                      }
                      if (props.viewOnly) {
                        nav("/view-reminders");
                      } else {
                        if (props.fullTemplate) {
                          nav("/cards");
                        } else {
                          nav("/reminder-cards");
                        }
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        {!!entity.data?.id && !props.fullTemplate && (
          <Tab eventKey="Notifications" title="Notifications">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Notifications") && (
                <NotificationsTab
                  list={entity.data?.notificationOutputDetailDtos || []}
                ></NotificationsTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && (
          <Tab eventKey="Comments" title="Comments">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Comments") && (
                <CommentsTab
                  entityId={entity.data.id}
                  onDialogOpen={(open) => {
                    props.modalOpened?.(open);
                  }}
                ></CommentsTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && (
          <Tab eventKey="Participants" title="Participants">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Participants") && (
                <ParticipantsTab
                  fullTemplate={props.fullTemplate || false}
                  isNew={!id}
                  entityId={id}
                  tabMode={true}
                  model={{
                    userFullName: model.userFullName,
                    userId: model.userId,
                    participants: model.participants,
                  }}
                  myAccess={entity.data.currentUserAccess}
                  onChange={(data) => {}}
                ></ParticipantsTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && (
          <Tab eventKey="Files" title="Files">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Files") && (
                <FilesTab
                  entityId={entity.data?.id}
                  canAddEdit={
                    entity.data?.currentUserAccess ===
                      SysModels.FullTemplateEntityAccess.Admin ||
                    entity.data?.currentUserAccess ===
                      SysModels.FullTemplateEntityAccess.Contributor
                  }
                  dialogOpen={(open) => {
                    props?.modalOpened?.(open);
                  }}
                ></FilesTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && (
          <Tab eventKey="Links" title="Links">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Links") && (
                <LinksTab
                  entityId={entity.data?.id}
                  canAddEdit={
                    entity.data?.currentUserAccess ===
                      SysModels.FullTemplateEntityAccess.Admin ||
                    entity.data?.currentUserAccess ===
                      SysModels.FullTemplateEntityAccess.Contributor
                  }
                  dialogOpen={(open) => {
                    props?.modalOpened?.(open);
                  }}
                ></LinksTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && (
          <Tab eventKey="Logs" title="Logs">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Logs") && (
                <div>
                  <ActivityLogs
                    type={SysModels.LogObjectTypeEnum.Entity}
                    stringId={id}
                    forTab={true}
                  ></ActivityLogs>
                </div>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Entity;
