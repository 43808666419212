import { makeAutoObservable, reaction } from "mobx";
import { useEffect, useState } from "react";
import SysModels from "../models";
import commonService from "../services/CommonService";
import { useLocation } from "react-router-dom";
import SysServices from "../services";

export type USER_ROLES =
  | "Admin"
  | "AppAdmin"
  | "ReminderManager"
  | "SaaSAdmin"
  | "TemplateManager"
  | "User"
  | "UserManager";

export interface ILoginStatus {
  loggedIn: boolean;
  canAccessWebsite: boolean;
  is2faRequired: boolean;
  mustDoSomething: {
    changePassword: boolean;
    need2faAuthentication: boolean;
  } | null;
  roles: USER_ROLES[];
  userName: string;
  email: string;
  isParentCompany: boolean;
  companySwitched: boolean;
  companyName: string;
  userId: string;
}
class SystemStore {
  private _tmoSystemMessage = 0;
  private _tmo10sec = 0;
  private _systemMessageVisible = false;
  private _mainContainerScrollTop = 0;

  private _windowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
    isSmall: window.innerWidth < 768,
  };

  private _loginStatus: ILoginStatus = {
    loggedIn: false,
    canAccessWebsite: false,
    is2faRequired: false,
    mustDoSomething: {
      changePassword: false,
      need2faAuthentication: false,
    },
    roles: [],
    userName: "",
    email: "",
    isParentCompany: false,
    companySwitched: false,
    companyName: "",
    userId: "",
  };

  private _activeRole?: USER_ROLES = undefined;
  private _errorDialog = {
    show: false,
    message: "",
    title: "",
    severity: SysModels.ErrorSeverityEnum.Information,
  };

  private _siteDown?: SysModels.SiteDownMessageDto;

  private _switchCompany = {
    ask: false,
    companyId: "",
    companyName: "",
    switching: false,
  };

  private _companyLogoUpdated?: string;
  private _companySettings?: SysModels.CompanySettingsDto;
  private _blockNav = false;

  get windowSize() {
    return this._windowSize;
  }

  get tmoSystemMessage() {
    return this._tmoSystemMessage;
  }

  get tmo10sec() {
    return this._tmo10sec;
  }

  get systemMessageVisible() {
    return this._systemMessageVisible;
  }

  get mainContainerScrollTop() {
    return this._mainContainerScrollTop;
  }

  get loginStatus() {
    return this._loginStatus;
  }

  get activeRole() {
    return this._activeRole;
  }

  get errorDialog() {
    return this._errorDialog;
  }

  get siteDown() {
    return this._siteDown;
  }

  get switchCompany() {
    return this._switchCompany;
  }

  get companyLogoUpdated() {
    return this._companyLogoUpdated;
  }

  get companySettings() {
    return this._companySettings;
  }

  get blockNav() {
    return this._blockNav;
  }

  hasRole(role?: string) {
    return this._loginStatus.roles
      .map((r) => r.toLowerCase())
      .includes(`${role || ""}`.replaceAll(" ", "").toLowerCase());
  }

  constructor() {
    makeAutoObservable(this);
    setInterval(() => {
      this.setTmoSystemMessage(this.tmoSystemMessage + 1);
    }, 60000);
    setInterval(() => {
      this.setTmo10sec(this.tmo10sec + 1);
    }, 10000);
  }

  updateWindowSize() {
    this._windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
      isSmall: window.innerWidth < 768,
    };
  }

  setTmoSystemMessage(tmo: any) {
    this._tmoSystemMessage = tmo > 100 ? 1 : tmo;
  }

  setTmo10sec(tmo: any) {
    this._tmo10sec = tmo > 100 ? 1 : tmo;
  }

  setSystemMessageVisible(val: boolean) {
    this._systemMessageVisible = val;
  }

  setMainContainerScrollTop(val: number) {
    this._mainContainerScrollTop = val;
  }

  clearAuthData = () => {
    this._loginStatus = {} as any;
    this.setActiveRole(undefined);
  };

  setAuthData(data: SysModels.TokenDto) {
    const json = commonService.parseJwt(data.token || "");
    const changePassword =
      (json?.must_change_password || "").toLowerCase().trim() === "true";

    const is2faRequired =
      (json?.requires_2fa || "").trim().toLowerCase() === "true";

    const need2faAuthentication =
      is2faRequired &&
      (json?.is_2fa_authenticated || "").trim().toLowerCase() === "false";

    const isParentCompany =
      (json?.is_parent_company || "").toLowerCase().trim() === "true";

    const companySwitched =
      (json?.company_switched || "").toLowerCase().trim() === "true";

    const roles =
      typeof json?.role === "string" ? [json?.role] : [...(json?.role || [])];

    let doSomething = false;
    if (changePassword || need2faAuthentication) {
      doSomething = true;
    }

    this._loginStatus = {
      ...this._loginStatus,
      loggedIn: true,
      canAccessWebsite: true,
      is2faRequired: is2faRequired,
      mustDoSomething: doSomething
        ? { changePassword, need2faAuthentication }
        : null,
      roles: companySwitched
        ? [
            //...roles,
            "Admin",
            "ReminderManager",
            "TemplateManager",
            "User",
            "UserManager",
          ]
        : [...roles],
      userName: json.user_name,
      email: json.email,
      isParentCompany: isParentCompany,
      companySwitched: companySwitched,
      companyName: (json || {})["companyName"] || "",
      userId: (json || {})["id"] || "",
    };

    // if (!this.activeRole && roles && roles.length) {
    //   this.setActiveRole(roles[0]);
    // }
  }

  setActiveRole(role?: USER_ROLES) {
    this._activeRole = role || undefined;
  }

  setErrorDialog(
    show?: boolean,
    title?: string,
    message?: string,
    severity?: SysModels.ErrorSeverityEnum
  ) {
    let _title = title;
    if (severity === SysModels.ErrorSeverityEnum.Information) {
      _title = "Information";
    } else if (severity === SysModels.ErrorSeverityEnum.Warning) {
      _title = "Warning";
    } else {
      _title = title || "Error";
    }

    this._errorDialog = {
      show: show || false,
      message: message || "",
      title: _title || "",
      severity: severity || 0,
    };
  }

  setSiteDown(data?: SysModels.SiteDownMessageDto) {
    this._siteDown = data || undefined;
  }

  setSwitchingCompany(switching: boolean) {
    this._switchCompany = {
      ...this._switchCompany,
      switching,
    };
  }

  setSwitchCompany(ask = false, companyId = "", companyName = "") {
    this._switchCompany = {
      ...this._switchCompany,
      ask,
      companyId,
      companyName,
    };
  }

  setCompanyLogoUpdated() {
    this._companyLogoUpdated = new Date().toString();
  }

  setCompanySettings(settings?: SysModels.CompanySettingsDto) {
    const data = { ...(settings || {}) };
    this._companySettings = {
      ...data,
      entityLabelPlural: data.entityLabelPlural || "Cards",
      entityLabelSingular: data.entityLabelSingular || "Card",
      userLabelPlural: data.userLabelPlural || "Users",
      userLabelSingular: data.userLabelSingular || "User",
    };
    this.setCompanyLogoUpdated();
  }

  setBlockNav(val: boolean) {
    this._blockNav = val;
  }
}

const systemStore = new SystemStore();
export default systemStore;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(systemStore.windowSize);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, r) => {
        setWindowSize(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};

export const useLoginStatus = () => {
  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (n, p, r) => {
        setLoginStatus(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...loginStatus,
    hasRole: (role?: string) => {
      return loginStatus.roles
        .map((r) => r.toLowerCase())
        .includes(`${role || ""}`.replaceAll(" ", "").toLowerCase());
    },
  };
};

export const useActiveRole = () => {
  const [activeRole, setActiveRole] = useState(systemStore.activeRole);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.activeRole,
      (n, p, r) => {
        setActiveRole(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return activeRole;
};

export const useErrorDialog = () => {
  const [errorDialog, setErrorDialog] = useState(systemStore.errorDialog);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.errorDialog,
      (n, p, r) => {
        setErrorDialog(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return errorDialog;
};

export const useSiteDown = () => {
  const [siteDown, setSiteDown] = useState(systemStore.siteDown);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.siteDown,
      (n, p, r) => {
        setSiteDown(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return siteDown;
};

export const useSwitchCompany = () => {
  const [switchCompany, setSwitchCompany] = useState(systemStore.switchCompany);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.switchCompany,
      (n, p, r) => {
        setSwitchCompany(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return switchCompany;
};

export const useCompanyLogoUpdated = () => {
  const [companyLogoUpdated, setCompanyLogoUpdated] = useState(
    systemStore.companyLogoUpdated
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.companyLogoUpdated,
      (n, p, r) => {
        setCompanyLogoUpdated(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return companyLogoUpdated;
};

export const useCompanySettings = () => {
  const [companySettings, setCompanySettings] = useState(
    systemStore.companySettings
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.companySettings,
      (n, p, r) => {
        setCompanySettings(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return companySettings;
};

export const useBlockNav = () => {
  const [blockNav, setBlockNav] = useState(systemStore.blockNav);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.blockNav,
      (n, p, r) => {
        setBlockNav(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return blockNav;
};

export interface IPageFilters {
  pageSize: number;
  search: string;
  others?: { [key: string]: any };
}

/**
 *
 * @param defaults Define Default Values for Filters
 * @param onInitialize Method called on initial fetch of filters from backend. If no data, will send back defaults
 * @returns
 */
export const useLastPageFilters = (
  defaults?: IPageFilters,
  onInitialize?: (filters: IPageFilters) => void,
  customSettingId?: string
) => {
  const loc = useLocation();
  const settingId = encodeURIComponent(
    `PageFilters-${customSettingId || loc.pathname.replaceAll("/", "")}`
  );
  const [filters, setFilters] = useState<IPageFilters>();
  const [ready, setReady] = useState(false);

  const getSettings = () => {
    SysServices.http.userSetting
      .get(settingId)
      .then((data) => {
        //console.log(data);
        let valueSet = false;
        if (data?.setting && !commonService.isNullOrWhitespace(data?.setting)) {
          try {
            const rtn = JSON.parse(data.setting);
            setFilters(rtn);
            valueSet = true;
            onInitialize && onInitialize(rtn);
            setTimeout(() => {
              setReady(true);
            }, 100);
          } catch (error) {}
        }
        if (!valueSet) {
          //set defaults
          const dft: IPageFilters = {
            pageSize: defaults?.pageSize || 50,
            search: defaults?.search || "",
            others: defaults?.others,
          };
          setFilters(dft);
          onInitialize && onInitialize(dft);
          setTimeout(() => {
            setReady(true);
          }, 100);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    refresh: () => {
      setReady(false);
      getSettings();
    },
    ready,
    filters: filters,
    save: (data: IPageFilters) => {
      if (JSON.stringify(data) === JSON.stringify(filters)) {
        return; //do nothing
      }
      setFilters({
        ...filters,
        ...data,
      });
      SysServices.http.userSetting
        .save(settingId, {
          setting: JSON.stringify({
            ...filters,
            ...data,
          }),
        })
        .then((data) => {})
        .catch((err) => {
          console.error(err);
        });
    },
  };
};

export const useLoadedTab = (initList: string[]) => {
  const [activeTab, setActiveTab] = useState(initList[0] || "");
  const [loadedTabs, setLoadedTabs] = useState(initList);
  return {
    activeTab,
    setActiveTab: (tab: string) => {
      setActiveTab(tab);
      setLoadedTabs((prev) => {
        return [...prev.filter((x) => x !== tab), tab];
      });
    },
    loadedTabs,
    addLoadedTab: (tab: string) => {
      setLoadedTabs((prev) => {
        return [...prev.filter((x) => x !== tab), tab];
      });
    },
  };
};
