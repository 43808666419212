import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityLinkService {
  get = async (id: string) => {
    const url = `/EntityLink/${id}`;
    return fetcher.get<SysModels.EntityLinkOutputDto>(url);
  };

  create = async (model: SysModels.EntityLinkInputDto) => {
    const url = `/EntityLink`;
    return fetcher.post<SysModels.EntityLinkOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.EntityLinkUpdateDto) => {
    const url = `/EntityLink/${id}`;
    return fetcher.put<SysModels.EntityLinkOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/EntityLink/${id}`;
    return fetcher.delete<any>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.EntityLinkSearchDto
  ) => {
    const url = `/EntityLink/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.EntityLinkGridOutputDto>(url, model);
  };
}

const entityLink = new EntityLinkService();
export default entityLink;
